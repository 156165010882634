import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import call from '../../assets/icons/call.png'
import mail from '../../assets/icons/mail.png'
import { Col, Row } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { IMaskInput } from 'react-imask';
import FormControl from '@mui/material/FormControl';
import { InputLabel, MenuItem, Select } from '@mui/material'
import { NumericFormat } from 'react-number-format';
import ContinueButton from '../../Components/Buttons/ContinueButton/ContinueButton';
import FormInput from '../../Components/Inputs/FormInput/FormInput';
// import SelectOption from '../../Components/Inputs/SelectOption/SelectOption';
// import ChipInput from '../../Components/Inputs/ChipInput/ChipInput';
import { getMethodWithToken, postMethodWithToken, putMethodWithToken } from '../../utils/services/apis';
import url from '../../utils/services/urls.json';
import { useDropzone } from "react-dropzone";
import styled from 'styled-components';
import "./UserProfile.scss"
import DefaultImage from "../../assets/images/profileimage.png"
import ProfileIcon from "../../assets/images/profile.svg"
import { setUserId } from '../../utils/services/common';
import { toast } from 'react-toastify';
import { navigateLogin } from '../../utils/services/common';

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props;
    return (
        <IMaskInput
            {...other}
            mask="+1 (#00) 000-0000"
            definitions={{
                '#': /[1-9]/,
            }}
            inputRef={ref}
            onAccept={(value) => onChange({ target: { name: props?.name, value } })}
            overwrite
        />
    );
});

TextMaskCustom.propTypes = {
    onChange: PropTypes.func.isRequired,
};

const NumericFormatCustom = React.forwardRef(function NumericFormatCustom(
    props,
    ref,
) {
    const { onChange, ...other } = props;

    return (
        <NumericFormat
            {...other}
            getInputRef={ref}
            onValueChange={(telephone) => {
                onChange({
                    target: {
                        name: props?.name,
                        value: telephone.value,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
            prefix="$"
        />
    );
});

NumericFormatCustom.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
};

const MainDiv = styled.div`
font-family: Proxima_nova_reg;
// width:900px;
background: white;
padding: 20px 40px 0 40px;
border: 1px solid #C7C7C7;
margin-top: 20px;

// @media only screen and (min-width:1100px) and (max-width: 1300px) {
//     width:700px;
//   }
//   @media only screen and (max-width: 1150px) {
//     width:600px;
//   }

@media (max-width: 676px){
margin: 20px 0 20px 0;
padding: 20px 40px 0 20px;
}
`;
const UserProfile = ({ ref }) => {
    const navigate = useNavigate();
    const [continueButton, setcontinueButton] = useState(false);
    const params = useParams();
    const [Organisation, setOrganisation] = useState('');
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('')
    const [nameError, setNameError] = useState(false);
    const [companyName, setCompanyName] = useState('');
    const [email, setEmail] = useState('');
    const [profileEmail, setProfileEmail] = useState()
    const [profile, setProfile] = useState("")
    const [error, setError] = useState(false);
    const [dataMsg, setDataMsg] = useState({
        type: "",
        message: ""
    });
    const [organisationList, setOrganisationList] = useState([]);
    const [telephone, setTelephone] = React.useState({
        Telephone: '',
    });
    const [contactNumber, setContactNumber] = useState('')
    const [profileImageUpload, setProfileImageUpload] = useState([])
    const [profileUploadId, setProfileUploadId] = useState("")
    const [orgnizationSelect, setOrganisationSelect] = useState("")
    const [profileType, setProfileType] = useState([])
    const [profileValue, setProfileValue] = useState("")
    const [profileTypeId, setProfileTypeId] = useState("")
    const [selectProfile, setSelectProfile] = useState("")
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        setOrganisation(event.target.value);
        const selectedValue = event.target.value;
        const selectedItem = organisationList.find((item) => item.orgnization === selectedValue);
        setOrganisationSelect(selectedItem)
    }
    const [tags, setTags] = useState([])
    const sendData = (data, file) => {
        setTags(data)
    }
    useEffect(() => {
        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        if (true) {

            if (name.length && validationEmail && !error && telephone.Telephone.length == 17 && Organisation.length) {
                setcontinueButton(true)
            } else {
                setcontinueButton(false)
            }
        } else if (params.value == 'propertyManager') {
            if (name.length && validationEmail && !error && telephone.Telephone.length == 17) {
                setcontinueButton(true)
            } else {
                setcontinueButton(false)
            }
        }
    }, [name, email, telephone, Organisation, error])

    const getFormData = (event) => {
        event.preventDefault()
        const payloadFederal = {
            name: name,
            email: email,
            contact_number: telephone.Telephone,
            organisation: Number(Organisation),
            role: "user_role_federal"
        }
        const payloadProperty = {
            name: name,
            email: email,
            telephone: telephone,
            tags: tags,
            companyName: companyName,
            role: "user_role_manager"
        }
        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)
        if (params.value == 'federalEmployee') {
            if (name.length && validationEmail && !error && telephone.Telephone.length == 17 && Organisation.length) {

            }
        }
        else if (params.value == 'propertyManager') {
            if (name.length && validationEmail && !error && telephone.Telephone.length == 17) {

            }
        }
    };

    useEffect(() => {
        getProfile()
        getOrganisation()
        getProfileType()
    }, [])

    const getProfile = async () => {
        await getMethodWithToken(url.userProfile).then((response) => {
            if (response.status === 200 || response.status === 201) {
                localStorage.setItem("Id", response.data.id)
                setName(response.data.name && response?.data?.name)
                setPhoneNumber(response.data.contact_number && response?.data?.contact_number)
                setContactNumber(response.data.contact_number && response?.data?.contact_number)
                setEmail(response?.data?.email)
                setProfileEmail(response?.data?.email)
                setProfileImageUpload(response?.data?.profilePic)
                setOrganisation(response?.data?.organization && response?.data?.organization)
                setUserId(response?.data?.id)
                setProfileValue(response?.data?.user_access)
                setSelectProfile(response?.data?.user_access)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const getOrganisation = async () => {
        await getMethodWithToken(url.userOrganisation).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setOrganisationList(response?.data?.response)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const getProfileType = async () => {
        await getMethodWithToken(url.profileType).then((response) => {
            if (response.status === 200 || response.status === 201) {
                setProfileType(response?.data)
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const defaultValue = profileType && profileType.length > 0 ? profileType?.[0].name : '';

    const { getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, } = useDropzone({
        accept: '.jpg, .png, .jpeg',
        onDrop: (acceptedFile) => {
            setIsLoading(true);
            let formData = new FormData();
            acceptedFile.map((file, index) => {
                formData.append("file", file);
            });
            postMethodWithToken(url.profileImageUpload + "?query=profile", formData).then((response) => {
                if (response.status === 200 || response.status === 201) {
                    setProfileUploadId(response?.data?.id)
                    setProfileImageUpload(response?.data)
                }
            }).finally(() => {
                setIsLoading(false);
            });
        }
    });

    const handleProfileTypeChange = (event, key) => {
        setProfileValue(event?.target?.value)
        setProfileTypeId(key.key.substring(2))
    }

    const handleSubmitProfile = async () => {
        let body = {
            name: name,
            contact_number: contactNumber,
            profilePic: profileUploadId,
            "user_access": +profileTypeId

        }
        if (profileValue === selectProfile) {
            delete body["user_access"];
        } else {
            body["user_access"] = +profileTypeId;
        }
        await putMethodWithToken(url.updateUserProfile, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                toast.success(response?.data?.message)
                getProfile()
            } else {
                toast.error(response?.data?.message)
            }
        })
    }

    const isGovMilEmail = (value) => {
        if (!value) {
            return true;
        }
        const lowerCaseEmail = value.toLowerCase();
        if (!lowerCaseEmail.endsWith('.gov') && !lowerCaseEmail.endsWith('.mil')) {
            return false;
        }
        return true;
    };

    const isValidName = (input) => {
        const regex = /^[a-zA-Z\s_]+$/;
        return regex.test(input);
    };

    const handleNameChange = (e) => {
        const newName = e.target.value;
        setName(newName);
        setNameError(!isValidName(newName));
    };
    const handleTelephone = (event) => {
        const newNumber = event.target.value;
        setContactNumber(newNumber)
    };

    const handleReVerify = () => {
        navigate("federalEmployee/re-verify")
    }

    const getLastWordWithCapital = (sentence) => {
        const words = sentence.trim().split(" ");
        const lastWord = words[words.length - 1];
        const capitalizedLastWord = lastWord.charAt(0).toUpperCase();
        words[words.length - 1] = capitalizedLastWord + ".";
        return words.join(" ");
    }

    const capitalizeEachWord = (sentence) => {
        const words = sentence.trim().split(" ");
        const capitalizedWords = words.map(word =>
            word.charAt(0).toUpperCase() + word.slice(1)
        );
        return capitalizedWords.join(" ");
    }

    const aboutRef = useRef(null);
    const scrollToSection = (sectionId) => {
        const aboutUsPosition = aboutRef.current.getBoundingClientRect().top + window.scrollY;
        if (sectionId === 'about' && aboutRef.current) {
            window.scrollTo({
                top: aboutUsPosition - 0,  // Offset by 200px
                behavior: 'smooth'           // Smooth scrolling
            });
        }
    };

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }


    return (
        <Row>
            <Col >
                <MainDiv>
                    <div className='text-center' style={{ borderBottom: '1px solid #EAEAEA', paddingBottom: '24px' }}>
                        <div className="ppCircle">
                            {/* <img style={{ width: "127px", height: "127px", border: "1px solid black", boxShadow: ' 1px 4px #bdbbbb' }} src={profileImageUpload !== null ? profileImageUpload?.file : DefaultImage} alt="" /> */}
                            {isLoading ?
                                <div className="loader-container" >
                                    <div className="loader"></div>
                                </div>
                                :
                                <img style={{ width: "127px", height: "127px", border: "1px solid black", boxShadow: ' 1px 4px #bdbbbb' }} src={profileImageUpload !== null ? profileImageUpload?.file : DefaultImage} alt="" />
                            }
                            <div {...getRootProps()} style={{ border: "none" }}>
                                <input {...getInputProps()} />
                                {isDragAccept && <p>All files will be accepted</p>}
                                {isDragReject && <p>Some files will be rejected</p>}
                                {!isDragActive && (
                                    <div className="editBtn d-flex justify-content-center" style={{ cursor: "pointer" }}>
                                        <img src={ProfileIcon} alt="edit" className="align-self-center" />
                                    </div>
                                )}
                            </div>

                            <div className='main-heading text-center'>{name?.length > 0 && getUpparCaseName(name)}</div>
                            <div className="text d-none d-sm-flex justify-content-center" style={{ color: '#919CA5' }}>
                                <div className="d-flex align-items-center">
                                    <img src={call} alt="" style={{ maxWidth: '18px', maxHeight: '18px' }} />
                                    <div className="main-text">{phoneNumber}</div>
                                </div>
                                <div className="box d-flex align-items-center" style={{ marginLeft: '24px' }}>
                                    <img src={mail} alt="" style={{ maxWidth: '18px', maxHeight: '18px' }} />
                                    <div className="main-text">{profileEmail}</div>
                                </div>
                            </div>
                            <div className="d-sm-none mt-2" style={{ color: '#919CA5' }}>
                                <div className="d-flex align-items-center justify-content-center">
                                    <img src={call} alt="" style={{ maxWidth: '18px', maxHeight: '18px' }} />
                                    <div className="main-text">{phoneNumber}</div>
                                </div>
                                <div className="box d-flex align-items-center justify-content-center">
                                    <img src={mail} alt="" style={{ maxWidth: '18px', maxHeight: '18px' }} />
                                    <div className="main-text">{profileEmail}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={getFormData} className="input-fields">
                        <div className='name_field'>
                            <FormInput error={nameError} helperText={nameError ? 'Invalid characters in name' : ''} required={true} name={'Name'} id={'name'} type={'text'} value={name} onChange={(e) => handleNameChange(e)} ></FormInput>
                        </div>
                        <Row >
                            <Col sm={6} >
                                <FormInput required={true} disabled={true} name={'Email'} id={'email'} type={'email'} value={email} icon={mail}></FormInput>
                            </Col>
                            <Col sm={6} >
                                <FormInput required={true} name={'Telephone'} id={'telephone'} value={contactNumber} onChange={handleTelephone} icon={call} inputComponent={TextMaskCustom}></FormInput>
                            </Col>
                        </Row>
                        {/* <FormControl className='formControl' variant="standard" sx={{ m: 1 }}>
                            <SelectOption required={true} disabled={true} MenuProps={{ autoFocus: false }} name={'Organisation'} value={Organisation} id={'demo-simple-select-standard'} onChange={handleChange} options={organisationList}></SelectOption>
                        </FormControl> */}
                        <FormControl className='formControl mt-2' variant="standard" sx={{ m: 1 }}>
                            <InputLabel>Select Your Profile Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                value={profileValue}
                                required={true}
                                onChange={handleProfileTypeChange}
                            >
                                {profileType && profileType.length > 0 && profileType.map((data, key) => {
                                    return (
                                        <MenuItem key={data?.id} value={data?.name}>{data?.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>

                        <div className="button-login">
                            <ContinueButton backgroundColor={name && name.length > 0 && email && email.length > 0 && contactNumber && contactNumber.length > 0 && Organisation && Organisation.length > 0 ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={continueButton} text={'Save Changes'} height={'50px'} color={'#fff'} onClick={handleSubmitProfile} className="savechanges_button" />
                        </div>
                    </form>
                </MainDiv>
            </Col>
        </Row>
    )
}

export default UserProfile
