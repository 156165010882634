import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import OutlinedInput from '@mui/material/OutlinedInput';
import { TextField } from "@mui/material";
import { InputAdornment } from '@mui/material';
import { IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import { MenuItem, Select } from '@mui/material'
import Home from "../../../assets/images/favoriteProperty/home.svg"
import Furnishing from "../../../assets/images/favoriteProperty/furnishing.svg"
import Bedroom from "../../../assets/images/favoriteProperty/bedroom.svg"
import Bathroom from "../../../assets/images/favoriteProperty/bathroom.svg"
import Balcony from "../../../assets/images/favoriteProperty/balcony.svg"
import Clock from "../../../assets/images/favoriteProperty/clock.svg"
import { getMethodWithToken } from '../../../utils/services/apis';
import url from "../../../utils/services/urls.json"
import "./SearchNav.scss"
import FormInput from '../../../Components/Inputs/FormInput/FormInput';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Col, Row } from 'react-bootstrap';
import { Box } from '@mui/material';
import { navigateLogin } from '../../../utils/services/common';
import { useLocation, useNavigate } from 'react-router-dom';
import filterIcon from "../../../assets/images/filter.svg"
import Modal from "react-modal"
import closeIocn from "../../../assets/images/searchproperty/close.svg"
import ContinueButton from "../../../Components/Buttons/ContinueButton/ContinueButton"

const SearchConatiner = styled.div`
padding-top:60px;
padding-bottom:30px;

  @media (max-width:575px) {
    display: none !important;
  }
`
const SearchConatinerMobile = styled.div`
margin-top:43px;

`


const SearchNav = (props) => {
    const navigate = useNavigate()
    const location = useLocation()
    const [searchInput, setSearchInput] = useState("")
    const [priceRange, setPriceRange] = useState("")
    const [optionSelected, setOptionSelected] = useState(false);
    const [property, setProperty] = useState('')
    const [propertyId, setPropertyId] = useState('')
    const [propertyType, setPropertyType] = useState([])
    const [furnishingType, setFurnishingType] = useState([])
    const [petsArray, setPetsArray] = useState([])
    const [rentalDurationType, setRentalDurationType] = useState([])
    const [furnishing, setFurnishing] = useState("")
    const [furnishingId, setFurnishingId] = useState('')
    const [bedroom, setBedroom] = useState("")
    const [bathroom, setBathroom] = useState("")
    const [petsValue, setpetsValue] = useState("")
    const [petsId, setPetsId] = useState('')
    const [availability, setAvailability] = useState(null);
    const [startDate, setStartDate] = useState(null)
    const [endDate, setEndDate] = useState(null)
    const [rental, setRental] = useState("")
    const [rentalNewId, setNewRentalId] = useState("")
    const [filter, setFilter] = useState("")
    const [minPrice, setMinPrice] = useState('')
    const [maxPrice, setMaxPrice] = useState('')
    const [shortTerm, setShortTerm] = useState("")
    const [midTerm, setMidTerm] = useState("")
    const [longTerm, setLongTerm] = useState("")
    const [openModal, setOpenModal] = useState(false)
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });



    const customStyles = {
        content: {
            top: screenSize.width < 576 ? '100%' : '50%',
            left: '50%',
            bottom: screenSize.width < 576 ? '0px' : 'auto',
            transform: 'translate(-50%, -50%)',
            width: screenSize.width < 576 ? "100%" : "700px",
            height: screenSize.width < 576 ? "172vh" : "230px",
            padding: '0px',
            borderRadius: screenSize.width < 576 && '24px 24px 0px 0px',
            right: screenSize.width > 576 && 'auto',
            bottom: 'auto',
            marginRight: screenSize.width > 576 && '-50%',

        },
    };

    const handleData = () => {
        props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm });
    }

    useEffect(() => {
        if (openModal) {
            document.body.style.overflowY = 'hidden';
        } else {
            document.body.style.overflowY = 'auto';
        }
        return () => {
            document.body.style.overflowY = 'auto';
        };
    }, [openModal]);


    useEffect(() => {
        handleApartment()
    }, [])

    // useEffect(() => {
    //     handleData()
    // }, [propertyId, bedroom, bathroom, petsId, furnishingId, minPrice, maxPrice, startDate, endDate, shortTerm, longTerm, midTerm])


    const handleSearchInputChange = (event) => {
        setSearchInput(event.target.value)

    }

    const handleSearchClick = (value) => {
        // props.onClick()
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm })
        }
    }

    const handlePriceRange = () => {
        setPriceRange(`${minPrice}-${maxPrice}`);
        setOptionSelected(true);
    };

    const handleMinPriceChange = (e) => {
        setMinPrice(e.target.value);
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: e.target.value, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm })
        }
    };

    const handleMaxPriceChange = (e) => {
        setMaxPrice(e.target.value);
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: e.target.value, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm })
        }
    };

    const handlePropertyType = (event) => {
        const newPropertyId = event.target.value;
        setProperty(newPropertyId)
        setPropertyId(newPropertyId.id);
        // handleData()
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            props.onData({ propertyId: newPropertyId.id, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm });
        }
    };
    const handleFurnishingChange = (event) => {
        setFurnishing(event?.target?.value)
        setFurnishingId(event?.target?.value?.id)
        // handleData()
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: event?.target?.value?.id, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm });
        }
    }
    const handleBedroomChange = (event) => {
        setBedroom(event.target.value)
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            props.onData({ propertyId: propertyId, bedroom: event.target.value, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm })
        }
    }
    const handleBathroomChange = (event) => {
        setBathroom(event.target.value)
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: event.target.value, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm })
        }
    }
    const handlePetsChange = (event) => {
        const newPropertyId = event.target.value;
        setpetsValue(newPropertyId)
        setPetsId(newPropertyId.id);
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: newPropertyId.id, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm })
        }
    }
    const handleStartChange = (event) => {
        setStartDate(event)
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? event : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm })
        }
    }
    const handleEndDateChange = (event) => {
        setEndDate(event)
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? event : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm })
        }
    }
    const handleRentalChange = (event) => {
        const newRentalId = event.target.value;
        if (!location?.state?.featureDetail && screenSize.width > 576) {
            if (event.target.value?.name === "Short Term") {
                setShortTerm(true)
                setMidTerm("")
                setLongTerm("")
                props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: true, midTerm: midTerm, longTerm: longTerm })
            } else if (event.target.value.name === "Mid Term") {
                setMidTerm(true)
                setShortTerm("")
                setLongTerm("")
                props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: true, longTerm: longTerm })
            } else if (event.target.value.name === "Long Term") {
                setLongTerm(true)
                setShortTerm("")
                setMidTerm("")
                props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: true })
            }
        }
        setRental(newRentalId)
        setNewRentalId(newRentalId?.id)
    }
    const handleFilterChange = (event) => {
        setFilter(event.target.value)
    }
    const handleApartment = async () => {
        await getMethodWithToken(url.selectList,).then((response) => {
            if (response.status == 200 || response.status == 201) {
                response?.data.map(function (item) {
                    if (item?.[1] == "property_type") {
                        setPropertyType(item?.[0])
                    }
                    if (item?.[1] == "furnished_type") {
                        setFurnishingType(item?.[0])
                    }
                    if (item?.[1] === "subscription_type") {
                        setRentalDurationType(item?.[0])
                    }
                    if (item?.[1] === "property_pets") {
                        setPetsArray(item?.[0])
                    }
                })
            } else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }

    const handleSelectClick = (e) => {
        e.stopPropagation();
    };

    const handleFilterModal = () => {
        setOpenModal(true)
        props.filterModal(true)
    }

    const handleCloseModal = () => {
        setOpenModal(false)
        props.filterModal(false)
    }

    const handleFilterProperty = () => {
        setOpenModal(false)
        props.filterModal(false)
        if (!location?.state?.featureDetail && screenSize.width < 576) {
            props.onData({ propertyId: propertyId, bedroom: bedroom, bathroom: bathroom, petsValue: petsId, furnishingId: furnishingId, searchAddress: searchInput, minPrice: minPrice, maxPrice: maxPrice, startDate: startDate ? startDate : "", endDate: endDate ? endDate : "", shortTerm: shortTerm, midTerm: midTerm, longTerm: longTerm });
        }
    }


    return (
        <>
            <SearchConatiner>
                <div className="nav-container">
                    <TextField
                        name="search"
                        label="Search for city or Locality"
                        variant="outlined"
                        value={searchInput}
                        onChange={handleSearchInputChange}
                        style={{ width: "450px", marginLeft: "7px" }}
                        InputLabelProps={{
                            style: {
                                zIndex: (!props.openRentModal && !props.contactModal) ? 1 : 0,
                            }
                        }}
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton>
                                        <div className="search-btn" onClick={() => handleSearchClick()}>
                                            <div>
                                                <SearchIcon style={{ color: "white", width: "15px", height: "15px" }} />
                                            </div>
                                            <div className="d-none d-sm-flex" style={{ color: "white", fontSize: "12px", fontWeight: 600, paddingLeft: "7px" }} >Search</div>
                                        </div>
                                    </IconButton>
                                </InputAdornment>
                            ),
                            style: {
                                height: "57px"
                            }
                        }}
                    />
                    <FormControl sx={{ minWidth: 115, marginLeft: "7px" }}>
                        <Select
                            labelId="price-range-label"
                            value={priceRange}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handlePriceRange}
                            renderValue={(selected) => selected || "Price range"}
                            onClick={handleSelectClick}
                        >
                            {!optionSelected && (
                                <MenuItem value="">
                                    <Box onClick={(e) => e.stopPropagation()} sx={{ display: 'flex', gap: '10px', width: "229px" }}>
                                        <TextField
                                            name="Minimum"
                                            label="Minimum"
                                            type="number"
                                            value={minPrice}
                                            onChange={handleMinPriceChange}
                                            id="standard-basic" variant="standard" className="custom-textfield"
                                        />
                                        <p className="slash-value">-</p>
                                        <TextField
                                            name="Maximum"
                                            label="Maximum"
                                            type="number"
                                            value={maxPrice}
                                            onChange={handleMaxPriceChange}
                                            id="standard-basic" variant="standard" className="custom-textfield"
                                        />
                                    </Box>
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: "120px", marginLeft: "7px" }}>
                        <Select
                            value={property}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handlePropertyType}
                            startAdornment={
                                <InputAdornment position="end">
                                    <img src={Home} alt="" style={{ width: "20px", marginRight: "3px", marginTop: "-5px" }} />
                                </InputAdornment>
                            }
                        >
                            <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Property Type</MenuItem>
                            {propertyType.length > 0 && propertyType.map((data, key) => {
                                return (
                                    <MenuItem key={key} value={data} className='filter_items' >{data.name}</MenuItem>
                                )
                            })}

                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: "120px", marginLeft: "7px" }}>
                        <Select
                            value={furnishing}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handleFurnishingChange}
                            startAdornment={
                                <InputAdornment position="end">
                                    <img src={Furnishing} alt="" style={{ width: "20px", marginRight: "3px", marginTop: "-3px" }} />
                                </InputAdornment>
                            }
                        >
                            <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Furnishing</MenuItem>
                            {furnishingType.map((data, key) => {
                                return (
                                    <MenuItem value={data} key={key} className='filter_items'>{data.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120, marginLeft: "7px" }}>
                        <Select
                            value={bedroom}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="select-drop"
                            onChange={handleBedroomChange}
                            startAdornment={
                                <InputAdornment position="end">
                                    <img src={Bedroom} alt="" style={{ width: "20px", marginRight: "3px", marginTop: "-3px" }} />
                                </InputAdornment>
                            }
                        >
                            <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Bedrooms</MenuItem>
                            <MenuItem value={1} className='filter_items'>One</MenuItem>
                            <MenuItem value={2} className='filter_items'>Two</MenuItem>
                            <MenuItem value={3} className='filter_items'>Three</MenuItem>
                            <MenuItem value={4} className='filter_items'>Four</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120, marginLeft: "7px" }}>
                        <Select
                            value={bathroom}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="select-drop"
                            onChange={handleBathroomChange}
                            startAdornment={
                                <InputAdornment position="end">
                                    <img src={Bathroom} alt="" style={{ width: "20px", marginRight: "3px", marginTop: "-3px" }} />
                                </InputAdornment>
                            }
                        >
                            <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Bathrooms</MenuItem>
                            <MenuItem value={1} className='filter_items'>One</MenuItem>
                            <MenuItem value={2} className='filter_items'>Two</MenuItem>
                            <MenuItem value={3} className='filter_items'>Three</MenuItem>
                            <MenuItem value={4} className='filter_items'>Four</MenuItem>
                        </Select>
                    </FormControl>
                    <FormControl sx={{ minWidth: 120, marginLeft: "7px" }}>
                        <Select
                            value={petsValue}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            className="select-drop"
                            onChange={handlePetsChange}
                            startAdornment={
                                <InputAdornment position="end">
                                    <img src={Balcony} alt="" style={{ width: "20px", marginRight: "3px", marginTop: "-3px" }} />
                                </InputAdornment>
                            }
                        >
                            <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Pets</MenuItem>
                            {petsArray && petsArray.length > 0 && petsArray.map((data, key) => {
                                return (
                                    <MenuItem value={data} key={key} className='filter_items'>{data.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>

                    <FormControl sx={{ marginLeft: "7px", overflow: "hidden", maxWidth: 200, paddingTop: '0px' }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label={startDate ? "" : "From"} slotProps={{
                                    textField: {
                                        InputLabelProps: {
                                            style: {
                                                zIndex: (!props.openRentModal && !props.contactModal) ? 1 : 0,
                                            },
                                        },
                                    },
                                }} value={startDate} onChange={handleStartChange} />
                            </DemoContainer>
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ marginLeft: "7px", overflow: "visible", maxWidth: { xs: '100%', sm: 200 } }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DemoContainer components={['DatePicker']}>
                                <DatePicker label={endDate ? "" : "To"} value={endDate} onChange={handleEndDateChange} slotProps={{
                                    textField: {
                                        InputLabelProps: {
                                            style: {
                                                zIndex: (!props.openRentModal && !props.contactModal) ? 1 : 0,
                                            },
                                        },
                                    },
                                }}
                                />
                            </DemoContainer>
                        </LocalizationProvider>
                    </FormControl>

                    <FormControl sx={{ minWidth: 120, marginLeft: "7px" }}>
                        <Select
                            value={rental}
                            displayEmpty
                            inputProps={{ 'aria-label': 'Without label' }}
                            onChange={handleRentalChange}
                        >
                            <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Rental Duration</MenuItem>
                            {rentalDurationType.length > 0 && rentalDurationType.map((data, key) => {
                                return (
                                    <MenuItem key={key} value={data} className='filter_items' >{data.name}</MenuItem>
                                )
                            })}
                        </Select>
                    </FormControl>
                    {/* <FormControl sx={{ minWidth: 120, marginLeft: "7px" }}>
                    <Select
                        value={filter}
                        displayEmpty
                        inputProps={{ 'aria-label': 'Without label' }}
                        onChange={handleFilterChange}
                    >
                        <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>More Filters</MenuItem>
                        <MenuItem value={10} className='filter_items'>Ten</MenuItem>
                        <MenuItem value={20} className='filter_items'>Twenty</MenuItem>
                        <MenuItem value={30} className='filter_items'>Thirty</MenuItem>
                    </Select>
                </FormControl> */}
                </div>
            </SearchConatiner>

            {screenSize.width <= 575 &&
                <SearchConatinerMobile>
                    <Row className="g-2 mobile-section-property" style={{ background: (!openModal && !props.modalValue) ? "rgb(242, 242, 247)" : "", zIndex: (!openModal && !props.modalValue) ? "2" : "" }}>
                        <Col xs={10}>
                            <div>
                                <TextField
                                    name="search"
                                    label="Search for city or Locality"
                                    variant="outlined"
                                    fullWidth
                                    value={searchInput}
                                    onChange={handleSearchInputChange}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton>
                                                    <div className="search-btn" onClick={() => handleSearchClick()}>
                                                        <div>
                                                            <SearchIcon style={{ color: "white", width: "15px", height: "15px" }} />
                                                        </div>
                                                    </div>
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />

                            </div>
                        </Col>
                        <Col xs={2}>
                            <img src={filterIcon} alt="" style={{ height: "56px", cursor: "pointer" }} onClick={() => handleFilterModal()} />
                        </Col>
                    </Row>
                </SearchConatinerMobile>
            }

            <Modal isOpen={openModal} style={customStyles} overlayClassName="myoverlay">
                <div className="modal-container-search">
                    <div className="d-flex justify-content-between">
                        <div className="filter-heading">Filter</div>
                        <img src={closeIocn} alt="" onClick={() => handleCloseModal()} />
                    </div>
                    <div className="modal-price">Price Range</div>
                    <Row className="g-2 mt-2">
                        <Col xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <OutlinedInput
                                    fullWidth
                                    name="Minimum"
                                    type="number"
                                    value={minPrice}
                                    onChange={handleMinPriceChange}
                                    id="outlined-adornment-weight"
                                    aria-describedby="outlined-weight-helper-text"
                                    startAdornment={
                                        <InputAdornment position="start" sx={{ paddingLeft: 1 }}>
                                            $
                                        </InputAdornment>
                                    }
                                    placeholder="Minimum"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                            </FormControl>

                        </Col>
                        <Col xs={6}>
                            <FormControl fullWidth variant="outlined">
                                <OutlinedInput
                                    id="outlined-adornment-weight"
                                    name="Maximum"
                                    type="number"
                                    fullWidth
                                    value={maxPrice}
                                    onChange={handleMaxPriceChange}
                                    startAdornment={<InputAdornment position="start" sx={{ paddingLeft: 1 }}>$</InputAdornment>}
                                    aria-describedby="outlined-weight-helper-text"
                                    placeholder="Maximum"
                                    inputProps={{
                                        'aria-label': 'weight',
                                    }}
                                />
                            </FormControl>
                        </Col>
                        <Col xs={6} style={{ marginTop: "20px" }}>
                            <FormControl fullWidth>
                                <Select
                                    fullWidth
                                    value={property}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handlePropertyType}
                                    startAdornment={
                                        <InputAdornment position="end">
                                            <img src={Home} alt="" style={{ width: "20px", marginRight: "3px", marginTop: "-5px" }} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Property Type</MenuItem>
                                    {propertyType.length > 0 && propertyType.map((data, key) => {
                                        return (
                                            <MenuItem key={key} value={data} className='filter_items' >{data.name}</MenuItem>
                                        )
                                    })}

                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={6} style={{ marginTop: "20px" }}>
                            <FormControl fullWidth>
                                <Select
                                    fullWidth
                                    value={furnishing}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleFurnishingChange}
                                    startAdornment={
                                        <InputAdornment position="end">
                                            <img src={Furnishing} alt="" style={{ width: "20px", marginRight: "3px", marginTop: "-3px" }} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Furnishing</MenuItem>
                                    {furnishingType.map((data, key) => {
                                        return (
                                            <MenuItem value={data} key={key} className='filter_items'>{data.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Col>
                        <Col xs={6} style={{ marginTop: "20px" }}>
                            <FormControl fullWidth>
                                <Select
                                    fullWidth
                                    value={bedroom}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className="select-drop"
                                    onChange={handleBedroomChange}
                                    startAdornment={
                                        <InputAdornment position="end">
                                            <img src={Bedroom} alt="" style={{ width: "20px", marginRight: "3px", marginTop: "-3px" }} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Bedrooms</MenuItem>
                                    <MenuItem value={1} className='filter_items'>One</MenuItem>
                                    <MenuItem value={2} className='filter_items'>Two</MenuItem>
                                    <MenuItem value={3} className='filter_items'>Three</MenuItem>
                                    <MenuItem value={4} className='filter_items'>Four</MenuItem>
                                </Select>
                            </FormControl>

                        </Col>
                        <Col xs={6} style={{ marginTop: "20px" }}>
                            <FormControl fullWidth >
                                <Select
                                    fullWidth
                                    value={bathroom}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className="select-drop"
                                    onChange={handleBathroomChange}
                                    startAdornment={
                                        <InputAdornment position="end">
                                            <img src={Bathroom} alt="" style={{ width: "20px", marginRight: "3px", marginTop: "-3px" }} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Bathrooms</MenuItem>
                                    <MenuItem value={1} className='filter_items'>One</MenuItem>
                                    <MenuItem value={2} className='filter_items'>Two</MenuItem>
                                    <MenuItem value={3} className='filter_items'>Three</MenuItem>
                                    <MenuItem value={4} className='filter_items'>Four</MenuItem>
                                </Select>
                            </FormControl>

                        </Col>
                        <Col xs={6} style={{ marginTop: "20px" }}>
                            <FormControl fullWidth>
                                <Select
                                    fullWidth
                                    value={petsValue}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    className="select-drop"
                                    onChange={handlePetsChange}
                                    startAdornment={
                                        <InputAdornment position="end">
                                            <img src={Balcony} alt="" style={{ width: "20px", marginRight: "3px", marginTop: "-3px" }} />
                                        </InputAdornment>
                                    }
                                >
                                    <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Pets</MenuItem>
                                    {petsArray && petsArray.length > 0 && petsArray.map((data, key) => {
                                        return (
                                            <MenuItem value={data} key={key} className='filter_items'>{data.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Col>

                        <Col xs={6} style={{ marginTop: "20px" }}>
                            <FormControl fullWidth>
                                <Select
                                    fullWidth
                                    value={rental}
                                    displayEmpty
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    onChange={handleRentalChange}
                                >
                                    <MenuItem value="" className='filter_items' style={{ color: '#2F86D1', fontWeight: '600' }}>Rental Duration</MenuItem>
                                    {rentalDurationType.length > 0 && rentalDurationType.map((data, key) => {
                                        return (
                                            <MenuItem key={key} value={data} className='filter_items' >{data.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Col>


                    </Row>
                    <div className="modal-price">Availability</div>
                    <Row className="g-2">
                        <Col xs={6} style={{ marginTop: "20px", paddingRight: "0px" }}>
                            <FormControl style={{ width: "100%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label={startDate ? "" : "From"}
                                            value={startDate}
                                            onChange={handleStartChange}
                                            fullWidth
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    style: { paddingRight: '0px', boxSizing: 'border-box' }, // Ensure full width without clipping
                                                },
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                        </Col>

                        <Col xs={6} style={{ marginTop: "20px", paddingRight: "0px" }}>
                            <FormControl style={{ width: "100%" }}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DemoContainer components={['DatePicker']}>
                                        <DatePicker
                                            label={endDate ? "" : "To"}
                                            value={endDate}
                                            onChange={handleEndDateChange}
                                            fullWidth
                                            slotProps={{
                                                textField: {
                                                    fullWidth: true,
                                                    style: { paddingRight: '0px', boxSizing: 'border-box' }, // Ensure full width without clipping
                                                },
                                            }}
                                        />
                                    </DemoContainer>
                                </LocalizationProvider>
                            </FormControl>
                        </Col>

                        <Col xs={12} className="modal-button">
                            <ContinueButton text={'Apply Filters'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={"100%"} borderRadius={"4px"} fontSize={"16px"} fontWeight={500} onClick={() => handleFilterProperty()} />
                        </Col>
                    </Row>

                </div>
            </Modal>
        </>
    )
}

export default SearchNav
