import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Col, Row } from 'react-bootstrap'
import "./AddManagerListing.scss"
import shareIcon from "../../../../assets/images/listingManager/share-list.svg"
import DetailProfile from "../../../../assets/images/listingManager/listing-manager-user.svg"
import PhoneIcon from "../../../../assets/images/listingManager/call.svg"
import Mail from "../../../../assets/images/listingManager/mail.svg"
import Message from "../../../../assets/images/listingManager/add-manager.svg"
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import userImage from "../../../../assets/images/listingManager/listing-manager-user.svg"
import LikeIcon from "../../../../assets/images/listingManager/like-property.svg"
import FilledLikeIcon from "../../../../assets/images/listingManager/filledLikedImage.svg";
import mobileFavIcon from "../../../../assets/images/listingManager/mobile-fav-icon.svg";
import filledMobileFav from "../../../../assets/images/listingManager/filled-mobile-fav.svg"
import FlagIcon from "../../../../assets/images/listingManager/flag.svg"
import Reply from "../../../../assets/images/listingManager/reply.svg"
import Bedroom from "../../../../assets/images/listingManager/bedroom.svg"
import Bathroom from "../../../../assets/images/listingManager/bathroom.svg"
import Parking from "../../../../assets/images/listingManager/parking.svg"
import Clock from "../../../../assets/images/listingManager/clock.svg"
import Home from "../../../../assets/images/listingManager/home.svg"
import Furnishing from "../../../../assets/images/listingManager/furnishing.svg"
import ContinueButton from "../../../../Components/Buttons/ContinueButton/ContinueButton"
import ArrowForward from "../../../../assets/images/listingManager/Arrow-property.svg"
import url from "../../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../../utils/services/apis';
import { toast } from 'react-toastify';
import DefaultImage from "../../../../assets/images/default-image.svg"
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import Modal from "react-modal";
import FormInput from '../../../../Components/Inputs/FormInput/FormInput';
import slots from '../../../../assets/images/searchproperty/slots.svg';
import ArrowRight from '../../../../assets/images/searchproperty/ArrowRight.svg';
import close from "../../../../assets/images/searchproperty/close.svg";
import back from "../../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../../assets/images/searchproperty/ford_month.svg";
import enGB from 'date-fns/locale/en-GB';
import { navigateLogin } from '../../../../utils/services/common';
import { addDays, addMonths, addYears } from 'date-fns'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: 'San Francisco – Oakland Bay Bridge, United States',
    imgPath:
      'https://images.unsplash.com/photo-1537944434965-cf4679d1a598?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bird',
    imgPath:
      'https://images.unsplash.com/photo-1538032746644-0212e812a9e7?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Bali, Indonesia',
    imgPath:
      'https://images.unsplash.com/photo-1537996194471-e657df975ab4?auto=format&fit=crop&w=400&h=250&q=80',
  },
  {
    label: 'NeONBRAND Digital Marketing, Las Vegas, United States',
    imgPath:
      'https://images.unsplash.com/photo-1518732714860-b62714ce0c59?auto=format&fit=crop&w=400&h=250&q=60',
  },
  {
    label: 'Goč, Serbia',
    imgPath:
      'https://images.unsplash.com/photo-1512341689857-198e7e2f3ca8?auto=format&fit=crop&w=400&h=250&q=60',
  },
];

const AddManagerListDetail = styled.div`
margin-top: 50px;
`
const AddManagerListingTab = styled.div`
font-family: Proxima_nova_reg;
// width:840px;
height: auto;
background: white;
// padding: 10px;
margin-bottom: 15px;
`;

const CustomImage = styled.img`
background:url('${props => props?.image}');
height: 182px;
width:100%;
background-size: cover;
// background-repeat: no-repeat;
// background-position: center center;

@media (min-width:915px) and (max-width: 1199px) {
     height: 162px;
   }

   @media (min-width: 820px) and (max-width: 915px) {
     height: 162px;
   }
      
 @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
  }

   @media (max-width:400px) {
   height: 190px;
  }

`

const AddManagerListing = ({ searchListId, data, filterModal, mobileModal }) => {
  const today = new Date()
  const navigate = useNavigate()
  const theme = useTheme();
  const moment = require('moment-timezone');
  const [managerDetailList, setManagerDetailList] = useState(data)
  const [addManager, setAddManager] = useState([])
  const [sliderImage, setSliderImage] = useState([])
  const [activeStep, setActiveStep] = useState(0);
  const [openRentModal, setOpenRentModal] = useState(false)
  const [selectedRentDate, setSelectedRentDate] = useState();
  const [selectedRentEndDate, setSelectedRentEndDate] = useState();
  const [rentPropertyId, setRentPropertyId] = useState('')
  const [rentPrice, setRentPrice] = useState("")
  const [rentalDuration, setRentalDuration] = useState("")
  const [arrayPropertyId, setArrayPropertyId] = useState([])
  // const maxSteps = sliderImage?.length;

  const [screenSize, getDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      // transform: 'translate(-50%, -50%)',
      // width: "27vw",
      // height: "680px",
      transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
      width: screenSize.width <= 800 ? "" : screenSize.width <= 1100 ? "" : "40vw",
      height: screenSize.width <= 800 ? "64vh" : screenSize.width <= 900 ? "55vh" : screenSize.width <= 950 ? "48vh" : screenSize.width <= 1100 ? "48vh" : screenSize.width <= 1700 ? "77vh" : "68vh",
      padding: '0px',
    },
  };

  // const handleNext = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  // const handleBack = () => {
  //   setActiveStep((prevActiveStep) => prevActiveStep - 1);
  // };

  // const handleStepChange = (step) => {
  //   setActiveStep(step);
  // };

  const handleNext = (data, galleryIndex) => {
    setActiveStep((prevSteps) => ({
      ...prevSteps,
      [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === data?.property_images?.length - 1
        ? 0
        : (prevSteps[data?.propertyMaster_id] || 0) + 1
    }));
  };

  const handleBack = (data, galleryIndex) => {

    setActiveStep((prevSteps) => ({
      ...prevSteps,
      [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === 0
        ? data?.property_images?.length - 1
        : (prevSteps[data?.propertyMaster_id] || data?.property_images?.length) - 1
    }))

  };

  const handleStepChange = (data, step) => {
    setActiveStep((prevSteps) => ({
      ...prevSteps,
      [data?.propertyMaster_id]: step
    }));
  };

  const getRandomNumberProperty = (number) => {
    return Math.floor(Math.random() * number);
  };


  useEffect(() => {
    if (data) {
      setManagerDetailList(data?.properties_list)
      let array = [...sliderImage]
      data?.properties_list?.[0]?.property_images?.length > 0 &&
        array.push(data?.properties_list?.[0]?.property_images)
      setSliderImage(array)
    }
    // getAddManagerDetailList()
  }, [data])

  const getAddManagerDetailList = async () => {
    await getMethodWithToken(url.getAddManagerDetail + searchListId + "/").then((response) => {
      if (response.status === 200 || response.status === 201) {
        setManagerDetailList(response?.data?.properties_list)
        setAddManager(response?.data?.manager_profile)
        if (response?.data?.properties_list?.length > 0) {
          response?.data?.properties_list.map((data, index) => (
            setSliderImage(data?.property_images)
          ))
        }
      } else if (response.status === 401) {
        navigateLogin()
        navigate("/federalEmployee/Login")
      }
    })
  }

  const maxSteps = sliderImage?.[0]?.length;

  const handleAddManager = async (id) => {
    let body = {
      manager_id: id
    }
    postMethodWithToken(url.addManager, body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        toast.success(response?.data?.message)
      }
    })
  }
  const getUpparCaseName = (name) => {
    const words = name.split(" ");
    return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
  }
  const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {

    const monthYear = date.toLocaleDateString(undefined, {
      month: 'long',
      year: 'numeric',
    });

    const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];


    return (
      <div className="custom-header">
        <div className='date_month'>
          <p className='monthyear'>{monthYear}</p>
          <div style={{ display: "flex", gap: "10px" }}>
            <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} />
            <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} />
          </div>

        </div>
        <div style={{ fontSize: '20px', justifyContent: "space-around", display: 'flex', fontFamily: 'Proxima_nove_reg', paddingBottom: '20px' }}>
          {days.map((day, index) => (
            <span key={index}>{day}</span>
          ))}
        </div>
      </div>
    );
  };

  const handleMainCalenderDate = (date) => {
    if (!selectedRentDate) {
      setSelectedRentDate(date);
      if (rentalDuration === "Long term") {
        const newEndDate = new Date(date);
        newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        setSelectedRentEndDate(newEndDate);
      }
    } else if (!selectedRentEndDate && date >= selectedRentEndDate) {
      setSelectedRentEndDate(date);
    } else {
      if (selectedRentDate && selectedRentEndDate) {
        setSelectedRentEndDate("")
        setSelectedRentDate("")
      }
      setSelectedRentDate(date);
      if (rentalDuration === "Long term") {
        const newEndDate = new Date(date);
        newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        setSelectedRentEndDate(newEndDate);
      }
    }
  };

  const handleRentProperty = async (id, allValues) => {
    let api = `${url.GetRentProperty}?rentProperty_id=${id}`;
    await getMethodWithToken(api).then((response) => {
      if (response.status === 200 || response.status === 201) {
        setSelectedRentDate(new Date(response.data.start_date));
        setSelectedRentEndDate(new Date(response.data.end_date));
      } else if (response.status === 401) {
        navigateLogin()
        navigate("/federalEmployee/Login")
      } else {
        setSelectedRentDate('');
        setSelectedRentEndDate('');
      }
    });
    setRentPropertyId(id);
    setRentalDuration(allValues?.subscription_details?.masterSubscrption)
    setRentPrice((Number(allValues?.subscription_details?.price) + Number(allValues?.subscription_details?.price) * 5 / 100) + Number(allValues?.subscription_details?.deposit_amount))
    setOpenRentModal(true);
  };

  const dates = addDays(selectedRentDate, 30);
  const midDate = addMonths(selectedRentDate, 10)
  const longDate = addYears(selectedRentDate, 1)

  const handleCloseClick = () => {
    setOpenRentModal(false)
  }

  const handleCloseRentClick = async (start_date, end_date) => {
    let body = {
      property_id: +rentPropertyId,
      start_date: start_date,
      end_date: end_date,
      is_rent: true
    }
    await postMethodWithToken(url.RentPropertyDates, body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        navigate(`/Dashboard/manager/PaymentDetail/${rentPropertyId}/managerDetail`, {
          state: {
            rentPrice: rentPrice
          }
        })
        setOpenRentModal(false)
      } else {
        toast.error(response?.data?.success)
      }
    })
  }

  const handleChatWithManager = async (propertyMasterId, bedroom, bathroom, propertyType, price, address, per_month, propertySize, chatId, profileValue) => {
    let perMonth = per_month === true ? "per_month" : "per_day";
    const receiverPropertyDetail = {
      Id: chatId,
      PropertyMasterId: propertyMasterId,
      bedroom: bedroom,
      bathroom: bathroom,
      propertyType: propertyType,
      price: price,
      address: address,
      per_month: perMonth,
      propertySize: propertySize

    }
    let body = {
      receiver: chatId,
      propertyMaster: propertyMasterId,
      content: JSON.stringify(receiverPropertyDetail)
    }

    await postMethodWithToken(url.sendMessage, body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        localStorage.setItem('receiverPropertyDetail', response?.data?.content)
        navigate("/Dashboard/Chats", {
          state: {
            receiverId: response?.data?.receiver,
            propertyMasterId: response?.data?.propertyMaster,
            profileValue: profileValue
          }
        })
      }
    })
  }

  const handleViewDetail = (propertyId) => {
    navigate(`/Dashboard/add/manager/details/${propertyId}/manage`)
  }
  const handleAddFavouriteProperty = async (propertyId) => {
    let array = [...arrayPropertyId]
    let favoriteClicked
    if (array.includes(propertyId)) {
      let index = array.indexOf(propertyId)
      array.splice(index, 1)
      favoriteClicked = false
    } else {
      array.push(propertyId)
      favoriteClicked = true
    }
    setArrayPropertyId(array)

    let body = {
      "property_id": propertyId,
      "favourite": favoriteClicked
    }
    await postMethodWithToken(url.addFavoriteProperty, body).then((response) => {
      if (response.status === 200 || response.status === 201) {
      }
    })
  }

  const getLastWordWithCapital = (sentence) => {
    const words = sentence.trim().split(" ");
    if (words?.length > 1) {
      const lastWord = words[words.length - 1];
      const capitalizedLastWord = lastWord.charAt(0).toUpperCase();
      words[words.length - 1] = capitalizedLastWord + ".";
    }
    return words.join(" ");
  }

  const capitalizeEachWord = (sentence) => {
    const words = sentence.trim().split(" ");
    const capitalizedWords = words.map(word =>
      word.charAt(0).toUpperCase() + word.slice(1)
    );
    return capitalizedWords.join(" ");
  }



  return (
    <>
      <Row>
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div className="add-manager-detail">
            <div className="manager-heading">Listings managed</div>
            <div className="add-detail-manager-seaction">
              {managerDetailList && managerDetailList.length > 0 ? managerDetailList.map((item, index) => {
                return (
                  <AddManagerListingTab>
                    <div className="manager-card-detail">
                      <Row style={{ margin: "0", padding: "0" }}>
                        <Col xs={12} sm={12} md={5} lg={4} xl={4} xxl={4} className='images-addManager mobile-fav-pos'>
                          <div className='d-md-none d-block mobile-favIcon' style={{ zIndex: (!filterModal && !mobileModal) ? "1" : "0" }}>
                            <img src={arrayPropertyId.includes(data?.propertyMaster_id) ? filledMobileFav : mobileFavIcon} alt="" style={{ width: "30px", height: "30px", cursor: "pointer" }} onClick={() => handleAddFavouriteProperty(data?.propertyMaster_id)} />
                          </div>
                          <div>
                            <SwipeableViews
                              axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                              index={activeStep[item?.propertyMaster_id] || 0}
                              onChangeIndex={(step) => handleStepChange(item, step)}
                              enableMouseEvents
                            >
                              {item?.property_images.map((step, index) => {
                                return (
                                  <>
                                    <div key={index}>
                                      <CustomImage image={step?.media?.file} alt={step?.label} />
                                    </div>

                                  </>
                                )
                              })}
                            </SwipeableViews>
                            <MobileStepper
                              position="static"
                              variant="text"
                              nextButton={
                                <Button size="small" style={{ color: "white" }} onClick={() => handleNext(item, index)}>

                                  {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                </Button>
                              }
                              backButton={
                                <Button size="small" style={{ color: "white" }} onClick={() => handleBack(item, index)}>
                                  {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                </Button>
                              }
                            />

                          </div>

                          <div class="d-flex justify-content-center rent-box">
                            <div>
                              <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "16px", color: "#505050", fontWeight: 400, }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                              <div className="rent-heading" >Rent</div>
                            </div>
                            <div><hr style={{ border: "1px solid black", height: "40px", marginLeft: "10px", marginTop: "20px" }} /></div>
                            <div>
                              <div className="deposit-price" style={{ marginLeft: "10px" }}>$<span>{data?.subscription_details?.deposit_amount}</span></div>
                              <div className="rent-heading" style={{ marginLeft: "25px" }}>Deposit</div>
                            </div>
                          </div>
                          <div className="deposit-type">
                            <div className="d-flex">
                              <div className="rent-heading" >Rent : </div>
                              <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                            </div>
                            <div className="d-flex">
                              <div className="rent-heading">Deposit : </div>
                              <div className="deposit-price">$<span>{data?.subscription_details?.deposit_amount}</span></div>

                            </div>
                          </div>
                        </Col>
                        <Col xs={12} sm={12} md={7} lg={8} xl={8} xxl={8}>
                          <div className="listing-card-detail" >
                            <div className="d-flex justify-content-between section-profile">
                              <div className="d-flex" >
                                <div>
                                  <img src={item?.Property_owner?.[0]?.profilePic} alt="" style={{ width: "50px", height: "50px", borderRadius: "50%" }} />
                                </div>
                                <div >
                                  <div className="d-none d-lg-flex" style={{ paddingLeft: "7px" }}>
                                    <div className="user-heading">{item?.Property_owner?.[0]?.name}</div>
                                    <div className="user-dot">.</div>
                                    <div className="property-owner">Property Owner</div>
                                  </div>
                                  <div className="d-lg-none" style={{ paddingLeft: "7px" }}>
                                    <div className="user-heading">{screenSize.width <= 575 ? capitalizeEachWord(getLastWordWithCapital(item?.Property_owner?.[0]?.name)) : item?.Property_owner?.[0]?.name}</div>
                                    <div className="property-owner">Property Owner</div>
                                  </div>
                                </div>

                              </div>
                              <div>
                                <div className="d-flex">
                                  <div className='d-none d-md-block'><img src={arrayPropertyId.includes(item?.propertyMaster_id) ? FilledLikeIcon : LikeIcon} alt="" style={{ width: "20px", height: "20px", cursor: "pointer" }} onClick={() => handleAddFavouriteProperty(item?.propertyMaster_id)} /></div>
                                  <div className='d-none d-md-block'><img src={FlagIcon} alt="" style={{ width: "20px", height: "20px", marginLeft: "10px" }} /></div>
                                </div>
                                <div className="d-flex d-md-none align-items-center mt-0  justify-content-end pb-2" style={{ cursor: "pointer", marginLeft: "10px" }}>
                                  <div className="view-heading" onClick={() => handleViewDetail(item?.propertyMaster_id)}>View Details</div>
                                  <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "15px", height: "15px" }} onClick={() => handleViewDetail(item?.propertyMaster_id)} /></div>
                                </div>
                              </div>
                            </div>
                            <div className="mobile-type">
                              <div className="d-flex">
                                <div className="rent-heading" >Rent : </div>
                                <div className="rental-price">${data?.subscription_details?.price}<span style={{ fontSize: "14px", fontWeight: 400, color: "#505050" }} >{data?.subscription_details?.per_month ? '/ month' : '/ day'}</span></div>
                              </div>
                              <div className="d-flex" style={{ marginLeft: "10px" }}>
                                <div className="rent-heading">Deposit : </div>
                                <div className="deposit-price">$<span>{data?.subscription_details?.deposit_amount}</span></div>

                              </div>
                            </div>
                            <div style={{ color: "#707070", fontSize: "16px", fontWeight: 500, marginTop: "5px" }}>{item?.location?.locality}</div>
                            <Row style={{ marginTop: "10px", borderBottom: "1px solid #B7B7B7", paddingBottom: "5px" }}>
                              <Col xs={6} className='d-flex'>
                                <div><img src={Bedroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                <div className="categroy-heading">Bedroom</div>
                                <div className="category-value">{item?.bedroomCount}</div>
                              </Col>
                              <Col xs={6} className='d-flex'>
                                <div ><img src={Bathroom} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                <div className="categroy-heading">Bathroom</div>
                                <div className="category-value" style={{ textOverflow: 'ellipsis' }}>{item?.propertyType?.name === "Room" ? item?.room_sharing?.name : item?.bathroomCount}</div>
                              </Col>

                            </Row>
                            <div>
                              <Row >
                                <Col md={6} lg={12} xl={12} xxl={12}>
                                  <div className="d-none d-sm-inline">
                                    <div className="d-flex" style={{ marginTop: "10px" }} >
                                      <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                      <div className="categroy-heading">Available from</div>
                                    </div>
                                    <div className="select-value-heading">{item?.subscription_details?.propertyAvailfrom !== null && moment?.tz(item?.subscription_details?.propertyAvailfrom?.[0], item?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                  </div>
                                </Col>
                                <Col md={6} lg={6} xl={6} xxl={6} className="ipaid-detail">

                                  <div className="d-flex justify-content-end" style={{ cursor: "pointer", marginTop: "10px" }}>
                                    <div className="view-heading" onClick={() => handleViewDetail(item?.propertyMaster_id)}>View Details</div>
                                    <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleViewDetail(item?.propertyMaster_id)} /></div>
                                  </div>
                                </Col>

                                <Col xs={6} className="d-flex d-sm-none">
                                  <div className="d-flex" style={{ marginTop: "10px" }} >
                                    <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                    <div className="categroy-heading">Available from</div>
                                  </div>
                                </Col>
                                <Col xs={6} className="d-flex d-sm-none">
                                  <div className="select-value-heading">{item?.subscription_details?.propertyAvailfrom !== null && moment?.tz(item?.subscription_details?.propertyAvailfrom?.[0], item?.subscription_details?.propertyAvailfrom?.[0]).format("YYYY-MM-DD")}</div>
                                </Col>


                              </Row>

                              <Row className="g-2 d-none d-md-flex" style={{ marginTop: "17px" }}>
                                <Col md={6} lg={4} xl={4} xxl={4} className='d-none d-md-flex'>
                                  <ContinueButton text={'Rent Property'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} borderRadius={"4px"} width={"100%"} fontSize={"13px"} fontWeight={500} onClick={() => handleRentProperty(item?.propertyMaster_id, item)} />
                                </Col>
                                <Col md={6} lg={4} xl={4} xxl={4} className='d-none d-md-flex'>
                                  <ContinueButton text={'Chat with Manager'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontSize={"13px"} fontWeight={500} width={"100%"} onClick={() => handleChatWithManager(item?.propertyMaster_id, item?.bedroomCount, item?.bathroomCount, item?.propertyType?.name, item?.subscription_details?.price, item?.location?.locality, item?.subscription_details?.per_month, item?.propertySize, item?.Property_owner?.[0]?.id, item?.Property_owner?.[0])} />
                                </Col>
                                <Col md={2} lg={4} xl={4} xxl={4} className="full-screen-detail">
                                  <div className="d-flex align-items-center mt-0 mt-md-2 justify-content-end pb-2 pb-md-0" style={{ cursor: "pointer", marginLeft: "10px" }}>
                                    <div className="view-heading" onClick={() => handleViewDetail(item?.propertyMaster_id)}>View Details</div>
                                    <div style={{ marginLeft: "5px" }}><img src={ArrowForward} alt="" style={{ width: "20px", height: "20px" }} onClick={() => handleViewDetail(item?.propertyMaster_id)} /></div>
                                  </div>
                                </Col>
                              </Row>

                            </div>
                          </div>

                        </Col>
                      </Row>
                    </div>
                  </AddManagerListingTab>
                )
              })
                :
                <Row>
                  <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12} >
                    <div className="empty-manager-list">No Manager List Found</div>
                  </Col>
                </Row>
              }
            </div>
          </div>

        </Col>
      </Row >


      <Modal style={customStyles} isOpen={openRentModal} overlayClassName="myoverlay" >
        <div className="modal-container" >
          <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
            <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Select date range</p>
              <img src={close} onClick={handleCloseClick} style={{ cursor: 'pointer' }} alt='close' /></div>
            <div style={{ display: "flex" }}>
              <div className='inputField_calender'>
                <FormInput
                  type='text' name={'Start Date'} id={'name'}
                  value={selectedRentDate ? selectedRentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                  readOnly />
              </div>
              <img src={ArrowRight} alt='right' />
              <div className='inputField_calender' style={{ position: "relative" }}>
                <FormInput
                  type='text' name={'End Date'} id={'name'}
                  value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                  readOnly={false} />
              </div>
            </div>
          </div>
          <div className='main_calender'>
            <DatePicker selected={selectedRentDate || selectedRentEndDate} onChange={handleMainCalenderDate} inline dateFormat="MMMM yyyy" dayClassName={(date) => {
              let classes = '';
              if (selectedRentDate && selectedRentEndDate) {
                if (date.getTime() === new Date(selectedRentDate).getTime()) {
                  classes += 'start-date';
                } else if (date.getTime() === new Date(selectedRentEndDate).getTime()) {
                  classes += 'end-date';
                } else if (date > new Date(selectedRentDate) && date < new Date(selectedRentEndDate)) {
                  classes += 'range-selected';
                }
              }
              return classes;
            }} renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
              <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
              locale={enGB}
              formatWeekDayShort={(locale, dayOfWeek) => {
                const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
                return days[dayOfWeek];
              }}
              minDate={today}
              maxDate={rentalDuration === "short term" ? dates : rentalDuration === "mid term" ? midDate : longDate}
            />
          </div>
          <div className="footer-section-modal">
            <div className='calender_footer'>
              <img src={slots} alt='Like' />
              <p className='footer_para'>Available Slots</p>
            </div>
            <div>
              <button className={selectedRentEndDate ? 'continue_button' : 'Disablecontine'} onClick={() => handleCloseRentClick(selectedRentDate, selectedRentEndDate)}>Continue to checkout</button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default AddManagerListing
