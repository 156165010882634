import React, { useEffect, useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import { useParams, useNavigate, useLocation } from 'react-router-dom';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import styled from 'styled-components';
import FormControl from '@mui/material/FormControl';
import { MenuItem, Select } from '@mui/material';
import Navbar from '../../../../../Components/commonComponents/navbar/Navbar';
import ArrowBack from "../../../../../assets/images/favoriteProperty/arrow-back.svg";
import CardImage from "../../../../../assets/images/card-image.svg";
import ListingImage from "../../../../../assets/images/listing-image.svg";
import BedroomImage from "../../../../../assets/images/favoriteProperty/bedroom.svg";
import BathroomImage from "../../../../../assets/images/favoriteProperty/bathroom.svg";
import ParkingImage from "../../../../../assets/images/favoriteProperty/parking.svg";
import Home from "../../../../../assets/images/favoriteProperty/home.svg";
import Clock from "../../../../../assets/images/favoriteProperty/clock.svg";
import Furnishing from "../../../../../assets/images/favoriteProperty/furnishing.svg";
import './PaymentDetail.scss';
import FormInput from '../../../../../Components/Inputs/FormInput/FormInput';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import url from "../../../../../utils/services/urls.json"
import { getMethodWithToken, postMethodWithToken } from '../../../../../utils/services/apis';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment/moment';
import { toast } from 'react-toastify';
import { getUserEmail } from '../../../../../utils/services/common';
import { navigateLogin } from '../../../../../utils/services/common';
import Modal from "react-modal";

const SearchDetailSelect = styled.div`
background: rgb(242, 242, 247);
height: auto;
font-family: Proxima_nova_reg;
`

const CustomImage = styled.img`
background:url('${props => props?.image}');
height: 182px;
width:100%;
background-size: cover;
// background-repeat: no-repeat;
// background-position: center center;

@media (min-width:915px) and (max-width: 1199px) {
     height: 162px;
   }

   @media (min-width: 820px) and (max-width: 915px) {
     height: 162px;
   }
      
 @media (min-width:575px) and  (max-width:768px) {
   height: 142px;
  }

   @media (min-width:431px) and  (max-width:575px) {
   height: 320px;
   margin-top: 10px;
  }

   @media  (min-width:400px) and  (max-width:431px) {
   height: 220px;
   margin-top: 10px;
  }

   @media (max-width:400px) {
   height: 190px;
   margin-top: 10px;
  }

`
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 400,
        flexGrow: 1,
    },
    header: {
        display: 'flex',
        alignItems: 'center',
        height: 50,
        paddingLeft: theme.spacing(4),
        backgroundColor: theme.palette.background.default,
    },
    img: {
        height: 163,
        display: 'block',
        maxWidth: 300,
        overflow: 'hidden',
        width: '100%',
    },
}));

const PaymentDetail = () => {
    const navigate = useNavigate()
    const params = useParams()
    const classes = useStyles();
    const theme = useTheme();
    const location = useLocation()
    const [activeStep, setActiveStep] = useState(0);
    const [carouselImages, setCarouselImages] = useState([])
    const [address, setAddress] = useState('')
    const [apartmentName, setApartmentName] = useState('')
    const [parkingType, setParkingType] = useState('')
    const [furnishType, setFurnishType] = useState('')
    const [availability, setAvailability] = useState('')
    const [couponCode, setCouponCode] = useState("")
    const [subscriptionPackage, setSubscriptionPackage] = useState("")
    const [billingPrice, setBillingPrice] = useState("")
    const [subscriptionMontly, setSubscriptionMontly] = useState("")
    const userId = localStorage.getItem("userId")
    const [reviewDetail, setReviewDetail] = useState([])
    const [paymentDetails, setPaymentDetails] = useState([])
    const [subscriptionPrice, SetSubscriptionPrice] = useState("")
    const maxSteps = carouselImages?.length;
    const selectSubscriptionPrice = localStorage.getItem("subscriptionPriceValue")
    const [subscriptionValue, setSubscriptionValue] = useState(localStorage.getItem("subscriptionData") !== "undefined" ? JSON.parse(localStorage.getItem("subscriptionData")) : "")
    const [viewDetailListData, setViewDetailListData] = useState([])
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });
    const [paymentVerifyModal, setPaymentVerifyModal] = useState(false)
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
            width: screenSize.width <= 540 ? "92vw" : screenSize.width <= 1100 ? "" : "40vw",
            display: "flex",
            height: "150px",
            fontFamily: 'Proxima_nova_reg',
            alignItems: "center"
        },

    };

    const handleCouponCodeChanges = (event) => {
        setCouponCode(event.target.value)
    }
    const handlePackageChanges = (event) => {
        setSubscriptionPackage(event.target.value)
    }
    const handleBillingChanges = (event) => {
        setBillingPrice(event.target.value)
    }
    const handleSubscriptionMonthChanges = (event) => {
        setSubscriptionMontly(event.target.value)
    }
    const handleNext = (data) => {
        // setActiveStep((prevActiveStep) => prevActiveStep + 1);

        setActiveStep((prevActiveStep) =>
            prevActiveStep === data?.length - 1 ? 0 : prevActiveStep + 1
        );
    };
    const handleBack = (data) => {
        // setActiveStep((prevActiveStep) => prevActiveStep - 1);

        setActiveStep((prevActiveStep) =>
            prevActiveStep === 0 ? data?.length - 1 : prevActiveStep - 1
        );
    };

    const handleStepChange = (step) => {
        setActiveStep(step);
    };
    useEffect(() => {
        getReviewDetails()
        getSteps()
    }, [])
    const getSteps = async () => {
        await getMethodWithToken(url.selectlist + params.id + "/").then((response) => {
            if (response.status === 200 || response.status === 201) {
                let Detail = response?.data?.detail
                setViewDetailListData(Detail)
                setCarouselImages(Detail?.stepFive)
                setAddress(Detail?.stepThree?.locality)
                setApartmentName(Detail?.stepTwo?.apartmentName)
                setParkingType(Detail?.stepTwo?.parkingType?.name)
                setAvailability(Detail?.stepSix?.propertyAvailfrom)
                setFurnishType(Detail?.stepTwo?.furnishType?.name)
            }
            else if (response.status === 401) {
                navigateLogin()
                navigate("/federalEmployee/Login")
            }
        })
    }
    const getReviewDetails = async () => {
        let apiUrl;
        if (location?.state?.featureCheck) {
            apiUrl = url.getReviewDetail + params.id + "/?" + "userId=" + userId + "&is_featured=True"
        } else {
            apiUrl = url.getReviewDetail + params.id + "/?" + "userId=" + userId
        }
        await getMethodWithToken(apiUrl).then((response) => {
            setReviewDetail(response?.data?.[0])
            setPaymentDetails(response?.data?.[1])
            if (location?.state?.featureCheck) {
                if (localStorage.getItem("availabilityValue") !== localStorage.getItem("availabilityValueResponse")) {
                    SetSubscriptionPrice((+(response?.data?.[0]?.subscription_price) + +(response?.data?.[1]?.feature_list_price)))
                }
                else if (Object.keys(subscriptionValue).length > 0) {
                    SetSubscriptionPrice((+(response?.data?.[1]?.feature_list_price)))
                } else {
                    SetSubscriptionPrice((+(response?.data?.[0]?.subscription_price) + +(response?.data?.[1]?.feature_list_price)))
                }
            } else {
                SetSubscriptionPrice((+(response?.data?.[0]?.subscription_price)))
            }
        })
    }

    const getUpparCaseName = (name) => {
        const words = name.split(" ");
        return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
    }


    const paypalOptions = {
        // 'client-id': 'AbysTaXRkDPWv-JqxyNvz6AFQzvCYiq-Mftc2y2x3pJ3XPq6dj5hZL2kv3tnt1ZK0oBe98OObknnagJj',
        'client-id': "AcMXdFC-3VdF1HtFOJY8nEoF0CRuIcdMoVTrajLnwaR8nQQK6ze_ueDxevuKovSAM80lWUj283mpnCCl",
        'disable-funding': 'card',
        currency: 'USD',
    };


    const rentPricePayment = localStorage.getItem("rentPricePayment")

    const handleFeatureCollection = async () => {
        let body = {
            propertyMaster: params.id,
            property_collections: localStorage.getItem("collectionPropertyId"),
            is_featured_property: true
        }
        await postMethodWithToken(url.featureProperty, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                navigate(`/Dashboard/MyListings`)
            }
        })

    }

    var intervalId;

    const handleVerifyPayment = async (value) => {
        let body = {
            "payment_id": value?.purchase_units?.[0]?.payments?.captures?.[0]?.id,
            "property_id": params.id,
            "master_sub_id": +localStorage.getItem("masterListSubscriptionId")
        }
        await postMethodWithToken(url.paymentVerify, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                handleFeatureCollection()
                clearInterval(intervalId)
                setPaymentVerifyModal(false)
                toast.success(response?.data?.success)
                localStorage.removeItem("PropertyType")
                localStorage.removeItem("name")
                localStorage.removeItem("bedroom");
                localStorage.removeItem("bathroom")
                localStorage.removeItem("balcony")
                localStorage.removeItem("propertysize")
                localStorage.removeItem("furnishingid");
                localStorage.removeItem("furnishing");
                localStorage.removeItem("parkingname")
                localStorage.removeItem("parkingid")
                localStorage.removeItem("floorno")
                localStorage.removeItem("floorid")
                localStorage.removeItem("totalfloor")
                localStorage.removeItem("totalfloorid")
                localStorage.removeItem("storiesName")
                localStorage.removeItem("storiesId")
                localStorage.removeItem("petid")
                localStorage.removeItem("otherpet")
                localStorage.removeItem("locality")
                localStorage.removeItem("city")
                localStorage.removeItem("state")
                localStorage.removeItem("zipcode")
                localStorage.removeItem("latitude")
                localStorage.removeItem("latitude")
                localStorage.removeItem("direction")
                localStorage.removeItem("amenities");
                localStorage.removeItem("imageID")
                localStorage.removeItem("images")
                localStorage.removeItem("subscriptionbutton")
                localStorage.removeItem("masterSubscrption")
                localStorage.removeItem("date");
                localStorage.removeItem("inputvalue")
                localStorage.removeItem("checkpoint");
                localStorage.removeItem("showorganization");
                localStorage.removeItem("subscriptionType");
                localStorage.removeItem("subscriptionId");
                localStorage.removeItem("depositAmount");
                localStorage.removeItem("subscriptionPriceValue");
                localStorage.removeItem("availabilityValue");
                localStorage.removeItem("availabilityValueResponse");
                localStorage.removeItem("addComment")
                localStorage.removeItem("masterListSubscriptionId")
            } else {
                setPaymentVerifyModal(true)
            }
        })
    }


    const handleTransactionPayment = async (verifyValue) => {
        let body = {
            "propertyMaster": params.id,
            "transaction_status": verifyValue?.status,
            "payment_id": verifyValue?.purchase_units?.[0]?.payments?.captures?.[0]?.id,
            "email_address": getUserEmail(),
            "payer_id": verifyValue?.payer?.payer_id
        }
        await postMethodWithToken(url.PostPaymentVerify, body).then((response) => {
            if (response.status === 200 || response.status === 201) {
                if (location.pathname.includes('/Dashboard/FavoritePropertieList/PaymentDetail/')) {

                    intervalId = setInterval(() => {
                        if (location.pathname.includes('/Dashboard/FavoritePropertieList/PaymentDetail/')) {
                            handleVerifyPayment(verifyValue)
                        }
                    }, 5000);
                }
                toast.success(response?.data?.success)
            }
        })
    }

    return (
        <>
            <div style={{ overflowX: 'hidden', height: "100vh", background: "rgb(242, 242, 247)" }} className='searchdetail'>
                <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
                    <Navbar />
                </div>
                <SearchDetailSelect>
                    <Row className="g-2" style={{ margin: screenSize.width < 575 ? "80px 0px 0px 0px " : "80px 25px 0px 25px" }}>
                        <Col xl={1} xxl={1}>
                            <div className="arrow-box-payment" onClick={() => navigate(-1)}>
                                <img src={ArrowBack} alt="" />
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6}>

                            <div className="listing-payment">
                                <div className="listing-detail">
                                    <div className="arrow-back-payment" onClick={() => navigate(-1)}>
                                        <img src={ArrowBack} alt="" style={{ width: "27px", paddingRight: "10px", cursor: "pointer" }} />
                                        <div style={{ fontSize: "22px", fontWeight: 600 }} >Listing Details</div>
                                    </div>
                                    <div className="listing-main-heading" >Listing Details</div>
                                    <Row>
                                        <Col xs={12} sm={12} md={4} lg={4} xl={4} xxl={4}>
                                            <div>
                                                <SwipeableViews
                                                    axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                                    index={activeStep}
                                                    onChangeIndex={handleStepChange}
                                                    enableMouseEvents
                                                >
                                                    {carouselImages?.length > 0 && carouselImages.map((step, index) => {
                                                        return (
                                                            <>
                                                                <div key={index}>
                                                                    <CustomImage image={step?.file} alt={step.label} />
                                                                </div>

                                                            </>
                                                        )
                                                    })}
                                                </SwipeableViews>
                                                <MobileStepper
                                                    position="static"
                                                    variant="text"
                                                    nextButton={
                                                        <Button size="small" style={{ color: "white" }} onClick={() => handleNext(carouselImages)}>
                                                            {theme.direction === 'rtl' ? <ArrowBackIosIcon /> : <ArrowForwardIosIcon />}
                                                        </Button>
                                                    }
                                                    backButton={
                                                        <Button size="small" style={{ color: "white" }} onClick={() => handleBack(carouselImages)}>
                                                            {theme.direction === 'rtl' ? <ArrowForwardIosIcon /> : <ArrowBackIosIcon />}

                                                        </Button>
                                                    }
                                                />

                                            </div>

                                        </Col>
                                        <Col xs={12} sm={12} md={8} lg={8} xl={8} xxl={8}>
                                            <div className="listing-details-card">
                                                <div className="d-flex section-profile" >
                                                    <div className="listing-user-heading" >${reviewDetail?.price ?? rentPricePayment}</div>
                                                    <div className="listing-user-month"> / {reviewDetail?.per_day === true ? "day" : "month"}</div>
                                                </div>
                                                <div style={{ color: "#707070", fontSize: "14px", fontWeight: 500, marginTop: "5px" }}>{address}</div>
                                                <Row style={{ borderBottom: "1px solid #B7B7B7", paddingBottom: "5px", paddingTop: "8px" }} >
                                                    <Col xs={5} sm={5} md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                        <div><img src={BedroomImage} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading-type">Bedroom</div>
                                                        <div className="category-value">{reviewDetail?.bedroomCount}</div>
                                                    </Col>
                                                    <Col xs={5} sm={5} md={6} lg={6} xl={6} xxl={6} className='d-flex'>
                                                        <div ><img src={BathroomImage} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                        <div className="categroy-heading-type">Bathroom</div>
                                                        <div className="category-value">{reviewDetail?.Property_type === "Room" ? viewDetailListData?.stepTwo?.room_sharing?.name : reviewDetail?.bathroomCount}</div>
                                                    </Col>

                                                </Row>
                                                <Row>
                                                    <Col md={12} lg={12} xl={12} xxl={12}>
                                                        <Row className='align-items-center'>
                                                            <Col xs={5} md={12} className="d-flex" style={{ marginTop: "10px" }}>
                                                                <div><img src={Clock} alt="" style={{ width: "20px", height: "20px" }} /></div>
                                                                <div className="categroy-heading">Available from</div>
                                                            </Col>
                                                            <Col xs={7} md={12} className="select-value-heading">{moment(availability).format("YYYY-MM-DD")}</Col>
                                                        </Row>

                                                    </Col>

                                                </Row>
                                            </div>
                                        </Col>
                                    </Row>
                                    <div style={{ fontSize: "22px", fontWeight: 600, borderBottom: "1.8px solid #e1e3e1", marginTop: screenSize.width < 1100 ? "30px" : "50px", paddingLeft: screenSize.width < 575 && "10px" }}>Payment details</div>
                                    <div className="payment-card">
                                        <Row>
                                            {(Object.keys(subscriptionValue).length === 0 || localStorage.getItem("availabilityValue") !== localStorage.getItem("availabilityValueResponse")) &&
                                                <>
                                                    <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}><div className="payment-descrption mt-4" style={{ marginBottom: !location?.state?.featureCheck && "15px" }}>Subscription Price</div></Col>
                                                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1} ><div className="price-payment mt-4" style={{ marginBottom: !location?.state?.featureCheck && "15px" }}>${reviewDetail?.subscription_price}</div></Col>

                                                </>
                                            }
                                            {(location?.state?.featureCheck) &&
                                                <>
                                                    <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}><div className="payment-descrption mt-4" style={{ marginBottom: location?.state?.featureCheck && "15px" }}>Featured Listing</div></Col>
                                                    <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}><div className="price-payment mt-4" style={{ marginBottom: location?.state?.featureCheck && "15px" }}>${paymentDetails?.feature_list_price}</div></Col>
                                                </>
                                            }
                                            <hr />
                                            <Col xs={9} sm={9} md={9} lg={9} xl={9} xxl={9}><div className="payment-descrption mt-1">Total payable amount</div></Col>
                                            <Col xs={1} sm={1} md={1} lg={1} xl={1} xxl={1}><div className="price-payment mt-1">${subscriptionPrice}</div></Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={12} sm={12} md={12} lg={12} xl={5} xxl={5}>
                            <div className="payment-detail">
                                <div className="payment-heading" >Checkout</div>
                                <div className="d-flex justify-content-center">
                                    <div className="method-heading mt-4">Payment Method</div>
                                </div>
                                <div className="d-flex justify-content-center mt-3">
                                    <div style={{ marginLeft: "10px" }}>
                                        <PayPalScriptProvider options={paypalOptions} style={{ minHeight: '40px' }}>
                                            <PayPalButtons
                                                createOrder={(data, actions) => {
                                                    return actions.order.create({
                                                        "intent": "CAPTURE",
                                                        purchase_units: [
                                                            {
                                                                amount: {
                                                                    value: selectSubscriptionPrice ?? rentPricePayment,
                                                                },
                                                            },
                                                        ]
                                                    });
                                                }}
                                                style={{
                                                    color: "white",
                                                    height: 55
                                                }}
                                                onApprove={async (data, actions) => {
                                                    const order = await actions.order.capture();
                                                    if (order.status === "COMPLETED") {
                                                        handleTransactionPayment(order)
                                                    }
                                                }
                                                }
                                            />
                                        </PayPalScriptProvider>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </SearchDetailSelect>
            </div>
            <Modal style={customStyles} isOpen={paymentVerifyModal} overlayClassName="myoverlay" >
                <div className="modal-container">
                    <h2>Please wait until the payment status get updated.</h2>
                </div>

            </Modal>

        </>
    )
}

export default PaymentDetail
