import React, { useEffect } from 'react'
import styled from 'styled-components';
import { useParams, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Col, Row } from 'react-bootstrap'
import Navbar from '../../../Components/commonComponents/navbar/Navbar';
import ArrowBack from "../../../assets/images/favoriteProperty/arrow-back.svg"
import "./SearchHomeDetail.scss";
import Bedroom from '../../../assets/images/searchproperty/Bed.svg';
import Bathroom from '../../../assets/images/searchproperty/Bathtub.svg';
import Clock from "../../../assets/images/searchproperty/Clock.svg";
import Home from '../../../assets/images/searchproperty/HouseLine.svg';
import Chair from '../../../assets/images/searchproperty/Armchair.svg';
// import Key from '../../../assets/images/favoriteProperty/Key.svg';
import Car from "../../../assets/images/searchproperty/Car.svg";
import Balcony from '../../../assets/images/searchproperty/balcony.svg';
import prevArrowImage from '../../../assets/images/searchproperty/backwardarrow.svg';
import nextArrowImage from '../../../assets/images/searchproperty/forwardarrow.svg';
import Calender from "../../../assets/images/searchproperty/calendar_month.svg"
import share from "../../../assets/images/searchproperty/share.svg";
import Like from "../../../assets/images/searchproperty/favorite.svg";
import FilledLikeIcon from "../../../assets/images/listingManager/filledLikedImage.svg"
import Location from "../../../assets/images/searchproperty/location.svg";
import close from "../../../assets/images/searchproperty/close.svg";
import back from "../../../assets/images/searchproperty/back_month.svg";
import fordward from "../../../assets/images/searchproperty/ford_month.svg";
import FormInput from '../../../Components/Inputs/FormInput/FormInput';
import slots from '../../../assets/images/searchproperty/slots.svg';
import ArrowRight from '../../../assets/images/searchproperty/ArrowRight.svg';
import { toast } from "react-toastify";
import enGB from 'date-fns/locale/en-GB';
import Modal from "react-modal";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { getMethodWithToken, postMethodWithToken } from '../../../utils/services/apis';
import url from '../../../utils/services/urls.json'
import { navigateLogin } from '../../../utils/services/common';
import { addDays, addMonths, addYears } from 'date-fns'
import ContinueButton from '../../../Components/Buttons/ContinueButton/ContinueButton';
import ArrowBackChat from "../../../assets/images/arrow-back-chat.svg";
import directiion from "../../../assets/icons/directions.svg";
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import prevArrowMobile from '../../../assets/images/prev-arrow-detail-mobile.svg'
import nextArrowMobile from '../../../assets/images/next-arrow-detail-mobile.svg'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const SearchDetailSelect = styled.div`
background:#F2F2F7;
height:auto;
font-family: Proxima_nova_reg;
padding: 80px 25px 20px 25px;

   @media only screen and (max-width: 1150px) {
     padding: 80px 0px 20px 0px;
   }
  @media (max-width:767px){
  background:#FFFFFF;
  }
  @media (min-width:768px){
  height:100vh
  }
`

const CustomImage = styled.img`
background:url('${props => props.image}');
height: 459px;
width:98%;
background-size: cover;
// background-position: center center; 
// background-repeat: no-repeat

   @media only screen and (max-width: 768px) {
     position: relative;
   }
     @media only screen and (max-width: 780px) {
     position: relative;
     width:100% ;
   }
     @media (max-width:767px){
     height: 239px
     }
     
`

const CustomImageSelect = styled.img`
background:url('${props => props.image}');
background-size: cover;
background-position: center center;
background-repeat: no-repeat

   @media only screen and (max-width: 768px) {
     position: relative;
   }
     
`

const CustomImages = styled.img`
background:url('${props => props.image}');
height:239px;
width:100vw;
background-size: cover;
background-position: center center;
background-repeat: no-repeat;
 
`
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  rootValue: {
    maxWidth: 400,
    flexGrow: 1,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    // height: 210,
    display: 'block',
    // maxWidth: 300,
    overflow: 'hidden',
    width: '100%',
  },
}));

const SearchHomeDetails = () => {
  const navigate = useNavigate()
  const param = useParams()
  const theme = useTheme();
  const today = new Date()
  const classes = useStyles();
  const moment = require('moment-timezone');
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedRentDate, setSelectedRentDate] = useState(new Date());
  const [selectedRentEndDate, setSelectedRentEndDate] = useState(null);
  const [openModal, setOpenModal] = useState(false)
  const [openRentModal, setOpenRentModal] = useState(false)
  const [rentPropertyId, setRentPropertyId] = useState('')
  const [rentalDuration, setRentalDuration] = useState("")
  const [petarray, setPetarray] = useState([])
  const [mainImage, setMainImage] = useState('')
  const [isFavorite, setIsFavorite] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [viewDetail, setViewDetail] = useState([])
  const [activeStep, setActiveStep] = useState(0);
  const [value, setValue] = React.useState(0);
  const [screenSize, getDimension] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });

  const customStyles = {
    content: {
      top: screenSize.width < 767 ? '100%' : '50%',
      left: '50%',
      right: 'auto',
      bottom: screenSize.width < 767 ? '0px' : 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      width: screenSize.width < 767 ? '101%' : screenSize.width <= 800 ? "64vh" : screenSize.width <= 900 ? "55vh" : screenSize.width <= 950 ? "48vh" : screenSize.width <= 1100 ? "48vh" : screenSize.width <= 1700 ? "77vh" : "68vh",
      height: screenSize.width < 767 ? '1200px' : screenSize.width <= 800 ? '64vh' : screenSize.width === 820 ? '56vh' : screenSize.width <= 950 ? "48vh" : screenSize.width <= 1100 ? '48vh' : screenSize.width <= 1700 ? '77vh' : '75vh',
      padding: '0px',
      borderRadius: screenSize.width < 767 ? '24px 24px 0px 0px' : '8px',
      overflowX: screenSize.width < 767 ? 'hidden' : 'auto',
    },
  };


  const customStylesAvail = {
    content: {
      top: screenSize.width < 767 ? '95%' : '50%',
      left: '50%',
      right: 'auto',
      bottom: screenSize.width < 767 ? '0px' : 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)', // Same transform for all screen sizes
      width: screenSize.width < 767 ? '101%' : screenSize.width <= 800 ? "64vh" : screenSize.width <= 900 ? "55vh" : screenSize.width <= 950 ? "48vh" : screenSize.width <= 1100 ? "48vh" : screenSize.width <= 1700 ? "77vh" : "68vh",
      height: screenSize.width < 767 ? '1000px' : screenSize.width <= 800 ? '64vh' : screenSize.width === 820 ? '56vh' : screenSize.width <= 950 ? "48vh" : screenSize.width <= 1100 ? '48vh' : screenSize.width <= 1700 ? '77vh' : '75vh',
      padding: '0px',
      borderRadius: screenSize.width < 767 ? '24px 24px 0px 0px' : '8px',
    },
  };

  const [contactModal, setContactModal] = useState(false)

  const handleGetFilter = (val) => {
    setContactModal(val)
  }

  const CustomPrevArrow = (props) => (
    <div {...props} className="custom-arrow prev-arrow" style={{ zIndex: openModal === true || openRentModal === true || contactModal === true ? "0" : "1" }}>
      <img src={prevArrowImage} alt="Previous" />
    </div>
  );

  const CustomNextArrow = (props) => (
    <div {...props} className="custom-arrow next-arrow" style={{ zIndex: openModal === true || openRentModal === true || contactModal === true ? "0" : "1" }}>
      <img src={nextArrowImage} alt="Next" />
    </div>
  );
  const CustomPrevArrows = (props) => (
    <div {...props} className="custom-arrow-mobile prev-arrow-mobile" style={{ zIndex: openModal === true || openRentModal === true || contactModal === true ? "0" : "1" }}>
      <img src={prevArrowMobile} alt="Previous" />
    </div>
  );

  const CustomNextArrows = (props) => (
    <div {...props} className="custom-arrow-mobile next-arrow-mobile" style={{ zIndex: openModal === true || openRentModal === true || contactModal === true ? "0" : "1" }}>
      <img src={nextArrowMobile} alt="Next" />
    </div>
  );

  const settings = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };
  const setting = {
    dots: false,
    infinite: true,
    centerMode: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrows />,
    nextArrow: <CustomNextArrows />,
  };

  const CustomHeader = ({ date, decreaseMonth, increaseMonth }) => {
    const monthYear = date.toLocaleDateString(undefined, {
      month: 'long',
      year: 'numeric',
    });

    const days = ['Mon', 'Tue', 'Wed', 'Thus', 'Fri', 'Sat', 'Sun'];

    return (
      <div className="custom-header">
        <div className='date_month'>
          <p className='monthyear'>{monthYear}</p>
          <div style={{ display: "flex", gap: "10px" }}>
            <img src={back} onClick={decreaseMonth} alt='back' style={{ cursor: "pointer" }} className='forward-calender' />
            <img src={fordward} onClick={increaseMonth} alt='fordward' style={{ cursor: "pointer" }} className='forward-calender' />
          </div>

        </div>
        <div style={{ fontSize: screenSize.width < 767 ? "16px" : '20px', justifyContent: "space-around", display: 'flex', fontFamily: 'Proxima_nove_reg', padding: screenSize.width < 767 ? "0px 0px 10px 0px" : "0 0  20px 0" }}>
          {days.map((day, index) => (
            <span key={index}>{day}</span>
          ))}
        </div>
      </div>
    );
  };

  const handleImageClick = () => {
    setOpenModal(true)
  };
  const handleCloseClick = () => {
    setOpenModal(false)
  }

  const handleslidderImageClick = (index, image) => {
    setSelectedImageIndex(index);
    setMainImage(image)
  };
  const customDate = {
    '.react-datepicker__day-names': {
      display: 'none',
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  useEffect(() => {
    handleSearchDetail()
    scrollToTop()
  }, [])

  const handleStepChange = (data, step) => {
    setActiveStep((prevSteps) => ({
      ...prevSteps,
      [data?.propertyMaster_id]: step
    }));
  };

  const handleNext = (data, galleryIndex) => {

    setActiveStep((prevSteps) => ({
      ...prevSteps,
      [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === data?.property_images?.length - 1
        ? 0
        : (prevSteps[data?.propertyMaster_id] || 0) + 1
    }));
  };

  const handleBack = (data, galleryIndex) => {
    setActiveStep((prevSteps) => ({
      ...prevSteps,
      [data?.propertyMaster_id]: prevSteps[data?.propertyMaster_id] === 0
        ? data?.property_images?.length - 1
        : (prevSteps[data?.propertyMaster_id] || 0) - 1
    }))

  };


  const handleSearchDetail = async () => {
    await getMethodWithToken(url.viewDetail + param.id + '/').then((response) => {
      if (response.status === 200 || response.status === 201) {
        setViewDetail(response?.data?.detail)
        setRentalDuration(response?.data?.detail?.stepSix?.rentalDuration)
        setMainImage(response?.data?.detail?.stepFive?.[0]?.file)
        setIsFavorite(response?.data?.detail?.is_favourite)
        if (response?.data?.detail?.pets) {
          response?.data?.detail?.pets.forEach((pet) => {
            if (!petarray.includes(pet.name)) {
              petarray.push(pet.name);
            }
          });
          setPetarray(petarray);
        }
        localStorage.setItem("rentPricePayment", response?.data?.detail?.stepSix?.price)
      } else if (response.status === 401) {
        navigateLogin()
        navigate("/federalEmployee/Login")
      }
    });
  }

  const handleMainCalenderDate = (date) => {
    if (!selectedRentDate) {
      setSelectedRentDate(date);
      if (rentalDuration === "Long term") {
        const newEndDate = new Date(date);
        newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        setSelectedRentEndDate(newEndDate);
      }
    } else if (!selectedRentEndDate && date >= selectedRentDate) {
      setSelectedRentEndDate(date);
    } else {
      if (selectedRentDate && selectedRentEndDate) {
        setSelectedRentEndDate("")
        setSelectedRentDate("")
      }
      setSelectedRentDate(date);
      if (rentalDuration === "Long term") {
        const newEndDate = new Date(date);
        newEndDate.setFullYear(newEndDate.getFullYear() + 1);
        setSelectedRentEndDate(newEndDate);
      }
    }
  };


  const handleRentProperty = async (id) => {
    let api = `${url.GetRentProperty}?rentProperty_id=${id}`
    await getMethodWithToken(api).then((response) => {
      if (response?.data?.propertyMaster?.id === +id) {
        setSelectedRentDate(new Date(response?.data?.start_date))
        setSelectedRentEndDate(new Date(response?.data?.end_date))
      }
      else {
        setSelectedRentDate('')
        setSelectedRentEndDate('')
      }
    })
    setRentPropertyId(id)
    setOpenRentModal(true)
  }

  const dates = addDays(selectedRentDate, 30);
  const midDate = addMonths(selectedRentDate, 10)
  const longDate = addYears(selectedRentDate, 1)

  const handleCrossRentClick = () => {
    setOpenRentModal(false)
  }

  const handleCloseRentClick = async (start_date, end_date) => {
    let body = {
      property_id: +rentPropertyId,
      start_date: start_date,
      end_date: end_date,
      is_rent: true
    }
    await postMethodWithToken(url.RentPropertyDates, body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        if (param.feature) {
          navigate(`/Dashboard/feature/${param.id}/PaymentDetail`, {
            state: {
              price: viewDetail?.stepSix?.price,
              deposit: viewDetail?.stepSix?.deposit_amount
            }
          })
        } else if (param.manage) {
          navigate(`/Dashboard/manager/PaymentDetail/${param.id}/manage`, {
            state: {
              price: viewDetail?.stepSix?.price,
              deposit: viewDetail?.stepSix?.deposit_amount,
              managerDetail: "managerDetail"
            }
          })
        } else if (param.favourite) {
          navigate(`/Dashboard/FavoritePropertyList/PaymentDetail/${param.id}`, {
            state: {
              price: viewDetail?.stepSix?.price,
              deposit: viewDetail?.stepSix?.deposit_amount,
            }
          })
        }
        else {
          navigate(`/Dashboard/search/PaymentDetail/${param.id}/search`, {
            state: {
              price: viewDetail?.stepSix?.price,
              deposit: viewDetail?.stepSix?.deposit_amount,
            }
          })
        }
        setOpenRentModal(false)
      } else {
        toast.error(response?.data?.success);
      }
    })

  }

  const handleInputValue = (e) => {
    const { value } = e.target;
    const parsedDate = new Date(value);
    if (!isNaN(parsedDate.getTime())) {
      setSelectedDate(parsedDate);
    }
  };

  const handleChatWithManager = async (bedroom, bathroom, propertyType, price, address, per_month, propertySize, chatId, profileValue) => {

    let perMonth = per_month === true ? "per_month" : "per_day";
    const receiverPropertyDetail = {
      Id: chatId,
      PropertyMasterId: param?.id,
      bedroom: bedroom,
      bathroom: bathroom,
      propertyType: propertyType,
      price: price,
      address: address,
      per_month: perMonth,
      propertySize: propertySize

    }
    let body = {
      receiver: chatId,
      propertyMaster: param?.id,
      content: JSON.stringify(receiverPropertyDetail)
    }

    await postMethodWithToken(url.sendMessage, body).then((response) => {
      if (response.status === 200 || response.status === 201) {
        localStorage.setItem('receiverPropertyDetail', response?.data?.content)
        navigate("/Dashboard/Chats", {
          state: {
            receiverId: response?.data?.receiver,
            propertyMasterId: response?.data?.propertyMaster,
            profileValue: profileValue
          }
        })
      }
    })
  }
  const handleAddFavouriteProperty = async () => {
    setIsFavorite(!isFavorite)
    let body = {
      "property_id": param.id,
      "favourite": !isFavorite
    }
    await postMethodWithToken(url.addFavoriteProperty, body).then((response) => {
      if (response.status === 200 || response.status === 201) {

      }
    })
  }

  const getUpparCaseName = (name) => {
    const words = name.split(" ");
    return words.map((word) => word[0].toUpperCase() + word.substring(1)).join(" ")
  }
  const handleGetOpenModal = (data) => {

  }
  // useEffect(() => {
  //   if (openRentModal || openModal) {
  //     document.body.style.overflowY = 'hidden';
  //   } else {
  //     document.body.style.overflowY = 'auto';
  //   }
  //   return () => {
  //     document.body.style.overflowY = 'auto';
  //   };
  // }, [openRentModal, openModal]);

  useEffect(() => {
    if (openModal || openRentModal) {
      setTimeout(() => {
        document.body.style.overflow = 'hidden';
      }, 0);
    } else {
      document.body.style.overflow = 'auto';
    }
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [openModal, openRentModal]);

  return (
    <div style={{ overflowX: 'hidden' }} className='searchdetail'>
      <div style={{ background: '#f2f2f7', height: 'fit-content' }}>
        <Navbar getOpenModal={(val) => handleGetOpenModal(val)} getFilterModal={(val) => handleGetFilter(val)} />
      </div>
      <SearchDetailSelect >
        <Row>
          <Col lg={1}>
            <div className="arrow-box" onClick={() => navigate(-1)}>
              <img src={ArrowBack} alt="" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={5} xl={5} xxl={5} style={{ padding: '0px' }}>
            {/* <div className="arrow-back-responsive d-none d-md-block d-lg-none" onClick={() => navigate(-1)}>
              <img src={ArrowBack} alt="" />
            </div> */}
            <div className='d-flex d-md-none ps-3 justify-content-between pe-3 pb-2'>
              <img src={ArrowBackChat} alt="back" className='d-block d-md-none' style={{ cursor: "pointer" }} onClick={() => navigate(-1)} />
              <div className='d-flex gap-2'>
                <img src={share} alt='share' style={{ width: "24px" }} />
                <img src={isFavorite ? FilledLikeIcon : Like} alt='Like' style={{ width: "26px", cursor: "pointer" }} onClick={() => handleAddFavouriteProperty()} />
              </div>
            </div>
            <div className="view-detail-section">
              {/* <img src={mainImage} alt='mainImage' className='mainImage' /> */}
              <CustomImage image={mainImage} className='d-none d-md-block' />
              {/* <CustomImage image={mainImage} /> */}
              <Slider {...settings} className='d-none d-md-block'>
                {viewDetail?.stepFive && viewDetail?.stepFive?.length > 0 && viewDetail?.stepFive.map((data, index) => {
                  return (
                    <div className={`carousel-item ${selectedImageIndex === index ? 'selected' : ''}`} onClick={() => handleslidderImageClick(index, data?.file)} >
                      <CustomImageSelect image={data?.file} className="select-image-slider" />
                    </div>

                  )
                })}
              </Slider>
              <div className='d-md-none mobile-detail-slider'>
                <Slider {...setting} >
                  {Array.isArray(viewDetail?.stepFive) && viewDetail?.stepFive.length > 0 && viewDetail?.stepFive.map((data, index) => (
                    <div key={data?.id || index} className="list-property-slider">
                      <div className="property-card">
                        <div>
                          <CustomImages image={data?.file} />
                        </div>
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>



            </div>
          </Col>
          <Col xs={12} sm={12} md={12} lg={6} xl={6} xxl={6}>
            <div className="detail-conatiner-view-detail">
              <div className="d-flex justify-content-between detals-values">
                <div className="d-flex" style={{ alignItems: 'center' }} >
                  <div>
                    <img src={viewDetail?.Property_owner?.[0]?.profilePic} alt="" style={{ width: "60px", height: "60px", borderRadius: '50%', border: '1px solid grey' }} />
                  </div>
                  <div >
                    <div className="d-flex " style={{ paddingLeft: "7px", alignItems: 'baseline', gap: '5px' }}>
                      <div className="user-heading">{viewDetail?.Property_owner?.[0]?.name} </div>
                      <div className="user-dot">.</div>
                      <div className="property-owner">Property Owner</div>
                    </div>
                    {/* <div style={{ paddingLeft: "7px" }} className="property-owner"> {viewDetail?.Property_owner?.[0]?.orgnization}</div> */}
                  </div>

                </div>
                <div style={{ gap: '10px' }} className='d-none d-md-flex'>
                  <img src={share} alt='share' style={{ width: "24px" }} />
                  <img src={isFavorite ? FilledLikeIcon : Like} alt='Like' style={{ width: "26px", cursor: "pointer" }} onClick={() => handleAddFavouriteProperty()} />
                </div>
                <div className='d-block d-md-none'>
                  <img src={directiion} alt='direction' />
                </div>
              </div>
              <Row className="g-2">
                <Col xs={12} sm={12} md={6} lg={12} xl={6} xxl={6} style={{ display: "flex", alignContent: "center" }}>
                  <div className='calender' style={{ zIndex: (openModal || openRentModal || contactModal) ? '0' : '1' }}>
                    <img src={Calender} alt="Calendar" onClick={handleImageClick} style={{ cursor: 'pointer' }} className='calender-img' />
                    <span style={{ color: '#0075CA', cursor: 'pointer' }} onClick={handleImageClick} className='calender-text'>Check Availability</span>
                    <Modal style={customStylesAvail} isOpen={openModal} overlayClassName="myoverlay" >
                      <div className="modal-container" >
                        <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                          <div className="modal-main-heading  calender_header" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Availability</p>
                            <img src={close} onClick={handleCloseClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                          <div className='inputField_calender'>
                            <FormInput type='text' name={'Jump To'} id={'name'} value={selectedDate ? selectedDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'long', year: 'numeric' }) : ''} onChange={(e) => handleInputValue(e)} />
                          </div>

                        </div>
                        <div className='main_calender'>
                          <DatePicker
                            showNextMonths={false} minDate={today} style={customDate} selected={selectedDate} onChange={(date) => setSelectedDate(date)} inline dateFormat="MMMM yyyy" dayClassName={(date) => {
                              return date.getDay() === 0 || date.getDay() === 6 ? 'weekend' : '';
                            }} renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                              <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                            locale={enGB} formatWeekDayShort={(locale, dayOfWeek) => { return new Intl.DateTimeFormat('en', { weekday: 'short' }).format(new Date(0, 0, dayOfWeek)); }}
                          />
                        </div>
                        <div className="footer-section-modal">
                          <div className='calender_footer'>
                            <img src={slots} alt='Like' />
                            <p className='footer_para'>Available Slots</p>
                          </div>
                        </div>
                      </div>
                    </Modal>
                    <Modal style={customStyles} isOpen={openRentModal} overlayClassName="myoverlay" >
                      <div className="modal-container" >
                        <div style={{ borderBottom: " 1px solid #EAEAEA", marginBottom: "10px" }} >
                          <div className="modal-main-heading  calender_header mt-2" > <p style={{ fontSize: '24px', fontWeight: '600', fontFamily: 'Proxima_nova_reg', marginBottom: "0px" }}>Select the dates range</p>
                            <img src={close} onClick={handleCrossRentClick} style={{ cursor: 'pointer' }} alt='close' /></div>
                          <div className=' d-flex align-items-center justify-content-between calender-mobile-detail-rent'>
                            <div className='inputField_calender d-block inputField_calender_rent'>
                              <FormInput
                                type='text' name={'Start Date'} id={'name'}
                                value={selectedRentDate ? selectedRentDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                readOnly />
                            </div>
                            <img src={ArrowRight} alt='right' style={{ width: screenSize.width < 768 && '18px', height: screenSize.width < 768 && '18px' }} />
                            <div className='inputField_calender d-block inputField_calender_rent' style={{ position: "relative" }}>
                              <FormInput
                                type='text' name={'End Date'} id={'name'}
                                value={selectedRentEndDate ? selectedRentEndDate.toLocaleDateString('en-GB', { day: 'numeric', month: 'short', year: '2-digit' }) : ''}
                                readOnly={false} />
                            </div>
                          </div>
                        </div>
                        <div className='main_calender'>
                          <DatePicker selected={selectedRentDate || selectedRentEndDate} onChange={handleMainCalenderDate} inline dateFormat="MMMM yyyy" dayClassName={(date) => {
                            let classes = '';
                            if (selectedRentDate && selectedRentEndDate) {
                              if (date.getTime() === new Date(selectedRentDate).getTime()) {
                                classes += 'start-date';
                              } else if (date.getTime() === new Date(selectedRentEndDate).getTime()) {
                                classes += 'end-date';
                              } else if (date > new Date(selectedRentDate) && date < new Date(selectedRentEndDate)) {
                                classes += 'range-selected';
                              }
                            }
                            return classes;
                          }} renderCustomHeader={({ date, decreaseMonth, increaseMonth }) => (
                            <CustomHeader date={date} decreaseMonth={decreaseMonth} increaseMonth={increaseMonth} />)}
                            locale={enGB}
                            formatWeekDayShort={(locale, dayOfWeek) => {
                              const days = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
                              return days[dayOfWeek];
                            }}
                            minDate={today}
                            maxDate={rentalDuration === "short term" ? dates : rentalDuration === "mid term" ? midDate : longDate}
                          />
                        </div>
                        <div className="footer-section-modal">
                          <div className='calender_footer'>
                            <img src={slots} alt='Like' />
                            <p className='footer_para'>Available Slots</p>
                          </div>
                          <div>
                            <button className={selectedRentEndDate ? 'continue_button detail-continue-button' : 'detail-disable-button Disablecontine '} onClick={() => handleCloseRentClick(selectedRentDate, selectedRentEndDate)}>Continue to checkout</button>
                          </div>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </Col>
                <Col xs={6} sm={6} md={3} lg={4} xl={3} xxl={3} className="mt-sm-0 mt-md-0 mt-lg-3 mt-xl-0 mt-xxl-0 rentProperty-button" style={{ zIndex: (openModal || openRentModal || contactModal) ? '0' : '1' }}>
                  <ContinueButton text={'Rent Property'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'100%'} borderRadius={"4px"} fontSize={"14px"} fontWeight={500} onClick={() => handleRentProperty(param.id)} />
                </Col>
                <Col xs={6} sm={6} md={3} lg={4} xl={3} xxl={3} className="mt-sm-0 mt-md-0 mt-lg-3 mt-xl-0 mt-xxl-0" style={{ zIndex: (openModal || openRentModal || contactModal) ? '0' : '1' }}>

                  <ContinueButton text={'Message Owner'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} width={'100%'} borderRadius={"4px"} fontSize={"14px"} fontWeight={500} onClick={() => handleChatWithManager(viewDetail?.stepTwo?.bedroomCount, viewDetail?.stepTwo?.bathroomCount, viewDetail?.stepOne?.propertyType?.name, viewDetail?.stepSix?.price, viewDetail?.stepThree?.locality, viewDetail?.stepSix?.per_month, viewDetail?.stepTwo?.propertySize, viewDetail?.Property_owner?.[0]?.id, viewDetail?.Property_owner?.[0])} />

                </Col>
              </Row>
              <div>
                <Row className='detals-box'>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <p className='amount_seacrh'>Rent</p>
                    <div className='price' style={{ borderRight: '1px solid rgb(183, 183, 183)' }}>${viewDetail?.stepSix?.price} <span style={{ fontSize: '16px', fontWeight: '400', color: '#707070' }}>/{viewDetail?.stepSix?.per_month === true ? 'month' : 'day'}</span></div>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4}>
                    <p className='amount_seacrh'>Deposit</p>
                    <div className='price' style={{ borderRight: '1px solid rgb(183, 183, 183)' }}>${viewDetail?.stepSix?.deposit_amount}</div>
                  </Col>
                  <Col xs={4} sm={4} md={4} lg={4} xl={4} xxl={4} >
                    <p className='amount_seacrh'>Square Feet</p>
                    <div className='price'>{viewDetail?.stepTwo?.propertySize} sqft</div>
                  </Col>
                </Row>
                <div className='locationAddress'>
                  <img src={Location} alt="Location" />
                  <p className='address'> {viewDetail?.stepThree?.locality}</p>
                </div>
                <div >
                  <p className='property'>Property Details</p>
                  <Row className='property-details'>
                    <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className='housedetails bedroom-detail pe-xs-0 pe-sm-0' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      <div className='background-mobile'>
                        <img src={Bedroom} alt='bedroom' />
                        <div className='propertydetails'>
                          <div style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{viewDetail?.stepTwo?.bedroomCount}</div>
                          <p>Bedrooms</p>
                        </div>
                      </div>

                    </Col>
                    <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className='housedetails bedroom-detail' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      <div className='background-mobile'>
                        <img src={Bathroom} alt='bathroom' />
                        <div className='propertydetails'>
                          <div>{viewDetail?.stepOne?.propertyType?.name === "Room" ? viewDetail?.stepTwo?.room_sharing?.name : viewDetail?.stepTwo?.bathroomCount}</div>
                          <p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Bathroom</p>
                        </div>
                      </div>

                    </Col>
                    <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className='housedetails bedroom-detail' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      <div className='background-mobile'>
                        <img src={Balcony} alt='balcony' />
                        <div className='propertydetails'>
                          <div>{viewDetail?.stepTwo?.balconyCount}</div>
                          <p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>Balcony</p>
                        </div>
                      </div>

                    </Col>
                    <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className='housedetails home-detail' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      <div className='background-mobile'>
                        <img src={Home} alt='home' />
                        <div className='propertydetails'>
                          <p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{viewDetail?.stepOne?.propertyType?.name}</p>
                        </div>
                      </div>

                    </Col>
                    <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className='housedetails chair-detail' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      <div className='background-mobile'>
                        <img src={Chair} alt='chair' />
                        <div className='propertydetails'>
                          <p>{viewDetail?.stepTwo?.furnishType?.name?.length > 0 && getUpparCaseName(viewDetail?.stepTwo?.furnishType?.name)}</p>
                        </div>
                      </div>

                    </Col>
                    {/* <Col lg={3} md={6} sm={6} xs={6} className='housedetails' style={{ borderRight: '1px solid rgb(183, 183, 183)' }}>
                      <img src={Key} alt='Key' />
                      <div className='propertydetails'>
                        <p>{new Date(propertyAvailfrom).toLocaleDateString('en-GB')}</p>
                      </div>
                    </Col> */}
                    <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className='housedetails chair-detail' style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                      <div className='background-mobile'>
                        <img src={Car} alt='Car' />
                        <div className='propertydetails'>
                          <p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{viewDetail?.stepTwo?.parkingType?.name?.length > 0 && getUpparCaseName(viewDetail?.stepTwo?.parkingType?.name)}</p>
                        </div>
                      </div>

                    </Col>
                    <Col xs={6} sm={6} md={3} lg={3} xl={3} xxl={3} className='housedetails'>
                      <div className='background-mobile'>
                        <img src={Clock} alt='Clock' />
                        <div className='propertydetails'>
                          <p style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>{viewDetail?.stepSix?.propertyAvailfrom !== null && moment?.tz(viewDetail?.stepSix?.propertyAvailfrom, viewDetail?.stepSix?.propertyAvailfrom).format("YYYY-MM-DD")}</p>
                        </div>
                      </div>

                    </Col>
                  </Row>
                </div>
                <div>
                  <Row style={{ paddingTop: "20px" }}>
                    <Col xs={6} sm={6} md={3} lg={4} xl={4} xxl={4}>
                      <p className='details'>Floor</p>
                      <div><p className='conditions'>{viewDetail?.stepTwo?.totalFloor}</p></div>
                    </Col>
                    {/* <Col lg={4}>
                      <p className='details'>Property age</p>
                      <div><p className='conditions'>7 years</p></div>
                    </Col> */}
                    {/* <Col lg={4}>
                      <p className='details'>Deposit</p>
                      <div><p className='conditions'>${price}</p></div>
                    </Col> */}
                    {/* <Col lg={4} style={{ paddingTop: "30px" }}>
                      <p className='details'>House Type</p>
                      <div><p className='conditions'>{apartmentName}</p></div>
                    </Col> */}
                    <Col xs={6} sm={6} md={3} lg={4} xl={4} xxl={4} className='p-md-0'>
                      <p className='details'>Pets Allowed</p>
                      {petarray && petarray.length > 0 && petarray.map((pet, index) => (
                        <span key={pet} className='conditions'>
                          {pet}
                          {index !== petarray.length - 1 && <span>,</span>}
                        </span>
                      ))}
                    </Col>
                  </Row>
                </div>
                {(viewDetail?.stepTwo?.Comments === "" || viewDetail?.stepTwo?.Comments === null) ?
                  ""
                  :
                  <>
                    <div className='details' style={{ marginTop: "15px" }}>Comment</div>
                    <div className='mt-1' style={{ fontSize: "15px", color: "#505050" }}>
                      <p>{viewDetail?.stepTwo?.Comments}</p>

                    </div>
                  </>
                }
                <div className='details' style={{ marginTop: "20px" }}>Amenities</div>
                {viewDetail?.stepFour && viewDetail?.stepFour?.length > 0 && viewDetail?.stepFour.map((item, index) => {
                  return (
                    <div className="amenitie-box" >
                      <div className="amenitie-select d-flex">
                        <div >
                          <img src={item?.image} alt="" style={{ width: "24px", height: "24px" }} />
                        </div>
                        <div className="amenitie-select-heading" >{item?.name}</div>
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </Col>
        </Row>
      </SearchDetailSelect>
    </div >
  )
}

export default SearchHomeDetails
