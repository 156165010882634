import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import GovHome from '../../assets/images/GovHomeLogo.png'
import "./Login.scss";
import { FormControl, FormHelperText } from '@mui/material';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import mail from '../../assets/icons/mail.png'
import ContinueButton from '../../Components/Buttons/ContinueButton/ContinueButton';
import FormInput from '../../Components/Inputs/FormInput/FormInput';
import BackButton from '../../Components/Buttons/BackButton/BackButton';
import { postMethod } from '../../utils/services/apis';
import url from '../../utils/services/urls.json';
import FullScreenLoader from '../../Components/Loader/FullScreenLoader';
import { setParam, setToken } from '../../utils/services/common';
import { toast } from "react-toastify";
import Modal from "react-modal"
import { Col, Row } from 'react-bootstrap';
import FormInputPassword from '../../Components/Inputs/FirmInputPassord/FormInputPassword';
import ArrowBack from "../../assets/images/arrow_back-ipad.svg"

const Login = () => {
    const loginstyle = {
        fontFamily: "Proxima_nova_medium"
    }
    const navigate = useNavigate();
    const [screenSize, getDimension] = useState({
        width: window.innerWidth,
        height: window.innerHeight
    });

    const styleWithRelist = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            transform: screenSize.width <= 800 ? 'translate(-50%, -50%)' : 'translate(-50%, -50%)',
            width: screenSize.width <= 576 ? "90vw" : screenSize.width <= 915 ? "60vw" : screenSize.width <= 1100 ? "50vw" : "28vw",
            height: screenSize.width <= 576 ? "75vh" : screenSize.width <= 800 ? "61vh" : screenSize.width <= 900 ? "53vh" : screenSize.width <= 1000 ? "46vh" : screenSize.width <= 1100 ? "41vh" : "60vh",
            padding: "0px",
        },
    }

    const [continueButton, setcontinueButton] = useState(false);
    const params = useParams();
    const [showPassword, setShowPassword] = useState(false);
    const [password, setPassword] = useState('');
    const [email, setEmail] = useState('');
    const [errorFromApi, setErrorFromApi] = useState(false);
    const [showLoader, setShowLoader] = useState(false);
    const [emailError, setEmailError] = useState('');
    const handleClickShowPassword = () => setShowPassword((show) => !show);

    // const handleEmailchange = (event) => {
    //     setEmail(event.target.value)

    //     let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value);

    //     if (!validationEmail || !event.target.value.toLowerCase().endsWith('@rootinfosol.com')) {
    //         if (validationEmail) {
    //             setEmailError('Email must end with @rootinfosol.com');

    //         } else {
    //             setEmailError("")
    //         }
    //     } else {
    //         setEmailError('');
    //     }
    // }

    const handleEmailchange = (event) => {
        setEmail(event.target.value);

        let validationEmail = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(event.target.value);
        let isAllowedDomain = event.target.value.toLowerCase().endsWith('@rootinfosol.com') || event.target.value.toLowerCase().endsWith('.gov');

        if (!validationEmail || !isAllowedDomain) {
            if (validationEmail) {
                // setEmailError('Email must end with @rootinfosol.com or .gov');
                setEmailError("Please Provide Valid Approved Email .gov")
            } else {
                setEmailError('');
            }
        } else {
            setEmailError('');
        }
    };


    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };
    const [openModal, setOpenModal] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [lastName, setLastName] = useState("")
    const [emailAddress, setEmailAddress] = useState("")
    const [message, setMessage] = useState("")

    const handleFirstNameChange = (event) => {
        setFirstName(event.target.value.trimStart())
    }
    const handleLastNameChange = (event) => {
        setLastName(event.target.value.trimStart())
    }
    const handleEmailChange = (event) => {
        setEmailAddress(event.target.value.trimStart())
    }
    const handleMessageChange = (event) => {
        setMessage(event.target.value.trimStart())
    }

    const handleOpenModal = () => {
        setOpenModal(true)
        setFirstName("")
        setLastName("")
        setEmailAddress("")
        setMessage("")
    }
    const closeModal = () => {
        setOpenModal(false)
    }

    useEffect(() => {
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(emailAddress)) {
            setcontinueButton(true)
        } else {
            setcontinueButton(false)
        }
    }, [emailAddress])

    const handlePostContact = async () => {
        let body = {
            "first_name": firstName,
            "last_name": lastName,
            "email": emailAddress,
            "message": message
        }
        if (firstName !== "" && lastName !== "" && emailAddress !== "" && message !== "") {
            if (continueButton) {
                await postMethod(url.postContact, body).then((response) => {
                    if (response.status === 200 || response.status === 201) {
                        toast.success(response?.data?.success)
                        setOpenModal(false)
                    }
                })
            }
        } else {
            toast.error("Please Fill All Field")

        }
    }

    const handleRegister = () => {
        navigate(`/${params.value}/Registration`)
    }

    const getFormData = (event) => {
        event.preventDefault()
        const payload = {
            email: email,
            password: password
        }
        if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && password.length) {
            Login(payload)
        }
    };
    const Login = async (body) => {
        await postMethod(url.loginApi, body).then((response) => {
            if (response?.status === 200 || response?.status === 201) {
                setShowLoader(false)
                setToken(response.data.key)
                setParam(params.value)
                toast.success(response.data.success)
                navigate("/Dashboard/homePage")
            } else {
                setShowLoader(false)
                setErrorFromApi(true)
                toast.error(response.data.error);
            }
        })
    }

    // useEffect(() => {
    //     if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email) && email.toLowerCase().endsWith('@rootinfosol.com') && password.length >= 6) {
    //         setcontinueButton(true)
    //     } else {
    //         setcontinueButton(false)
    //     }
    //     setErrorFromApi(false)
    // }, [email, password])

    useEffect(() => {
        const validEmailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        const isValidEmail = validEmailPattern.test(email);
        const isAllowedDomain = email.toLowerCase().endsWith('@rootinfosol.com') || email.toLowerCase().endsWith('.gov');

        if (isValidEmail && isAllowedDomain && password.length >= 6) {
            setcontinueButton(true);
        } else {
            setcontinueButton(false);
        }
        setErrorFromApi(false);
    }, [email, password]);


    useEffect(() => {
        setParam(params.value);
    }, []);

    const handleForgotPassword = () => {
        navigate(`/${params.value}/ForgotPassword`)
    }
    return (
        <>
            <div className="login" style={loginstyle}>
                <div className="middleContent " >
                    <div>
                        <img className="arrow-back" src={ArrowBack} alt="" onClick={() => navigate(-1)} style={{ cursor: "pointer" }} />
                    </div>
                    <div className="logo">
                        <img className="logoImg" src={GovHome} alt="" />
                    </div>

                    <div className="mainFields">
                        <h3 className='text-center typeHeding' style={{ fontFamily: 'Proxima_nova_medium' }}>Login</h3>
                        <form onSubmit={getFormData} className="get-regsister">
                            <FormInput required={true} name={'Email'} id={'email'} type={'email'} value={email} onChange={(e) => handleEmailchange(e)} icon={`${mail}`}></FormInput>
                            {emailError && (
                                <span style={{ color: 'red', fontSize: '12px', marginLeft: "10px" }}>{emailError}</span>
                            )}
                            <FormInputPassword required={true} name={'Password'} id={'password'} type={showPassword ? 'text' : 'password'} value={password} onChange={(e) => setPassword(e.target.value)} showIcon={showPassword ? < Visibility /> : < VisibilityOff />}
                                onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword} ></FormInputPassword>
                            <FormHelperText className='text-danger' id="email" style={{ padding: '0 8px' }}>{errorFromApi ? 'Incorrect email or password. Please try again.' : ''}</FormHelperText>
                            <div className="d-flex justify-content-end mt-3" >
                                <div onClick={handleForgotPassword} style={{ color: 'var(--primary)', cursor: 'pointer' }}>Forgot Password?</div>
                            </div>
                            <div className="login-button">
                                <ContinueButton className={continueButton ? "continue" : "continueDisabled"} backgroundColor={continueButton ? 'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' : 'linear-gradient(180deg, #B4BDC4 0%, #919CA5 100%)'} disabled={!continueButton} text={'Login'} height={'50px'} color={'#fff'} />
                            </div>
                        </form>
                    </div>
                    {params.value == 'federalEmployee' &&
                        <div style={{ marginTop: "7%" }}>
                            <div className="d-flex justify-content-center">
                                <p style={{ fontSize: 'var(--medium-heading)' }} ><span style={{ color: 'var(--primary)', fontWeight: '500', cursor: "pointer" }} onClick={() => handleOpenModal()} className='mobile-contact'>Contact Us
                                </span> <span className="mobile-text">If you’re facing trouble while logging in</span></p>
                                {/* } */}
                            </div>
                            <div style={{ textAlign: 'center' }}>
                                <div style={{ fontSize: 'var(--medium-heading)' }}>Don't have account? <p style={{ color: 'var(--primary)', cursor: 'pointer', fontSize: "20px", fontWeight: '500' }} onClick={handleRegister}>Register here</p></div>
                            </div>
                        </div>}
                </div>
            </div>

            <BackButton className="back" backgroundColor='linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)' text={'Back'} height={'50px'} color={'#fff'} width={"150px"} onClick={() => navigate(-1)} />
            <FullScreenLoader showLoader={showLoader} />

            <Modal isOpen={openModal} style={styleWithRelist} overlayClassName="myoverlay">
                <div className="contact-modal">
                    <div className="contact-heading">Contact Detail</div>
                    <Row style={{ padding: "15px 20px 0 20px" }}>
                        <Col md={6}>
                            <div className="message-heading">First Name</div>
                            <TextField
                                fullWidth
                                required
                                value={firstName}
                                onChange={(e) => handleFirstNameChange(e)}
                                id="outlined-required"
                            />

                        </Col>
                        <Col md={6}>
                            <div className="message-heading">Last Name</div>
                            <TextField
                                fullWidth
                                required
                                value={lastName}
                                onChange={(e) => handleLastNameChange(e)}
                                id="outlined-required"
                            />
                        </Col>
                        <Col lg={12}>
                            <div className="message-heading" style={{ marginTop: "20px" }}>Email Address</div>
                            <TextField
                                fullWidth
                                required
                                value={emailAddress}
                                onChange={(e) => handleEmailChange(e)}
                                id="outlined-required"
                            />
                            {(!continueButton && emailAddress !== "") &&
                                <div className="email-error">Please Enter a Vaild Email</div>
                            }
                        </Col>
                        <Col lg={12}>
                            <div className="message-heading" style={{ marginTop: "15px" }}>Message</div>
                            <textarea name="postContent" style={{ width: '100%', boxSizing: 'border-box', border: "0.5px solid gray" }} value={message} onChange={(e) => handleMessageChange(e)} rows="5" />
                        </Col>
                        <Col lg={6} style={{ marginTop: "15px" }}>
                            <ContinueButton text={'Cancel'} color={'#2F86D1'} backgroundColor={'#fff'} border={'2px solid #2F86D1'} borderRadius={"4px"} fontWeight={500} width={"100%"} onClick={() => closeModal()} />
                        </Col>
                        <Col lg={6} style={{ marginTop: "15px" }}>
                            <ContinueButton text={'Save'} color={'#fff'} backgroundColor={'linear-gradient(180deg, #40A0E5 0%, #136BAB 100%)'} width={'100%'} borderRadius={"4px"} fontWeight={600} onClick={() => handlePostContact()} />
                        </Col>
                    </Row>
                </div>
            </Modal>
        </>
    )
}
export default Login;
